<div class="notes-tabs-header">
  <ul ngbNav #notesNav="ngbNav" class="nav-pills flex-row">
    <li id="{{ link.route }}" [ngbNavItem]="link.route" *ngFor="let link of commentTabs" class="me-4 pe-2 my-3"
      [domId]="link.key">
      <a ngbNavLink (click)="onSelectedTab(link)">{{ link.title}}</a>
      <ng-template ngbNavContent>
        <div class="comments-layout position-relative">
          <div class="d-flex loader justify-content-center align-items-center mt-5 pt-5" *ngIf="loader">
            <div class="spinner">
              <div class="spinner__circle">
                <div class="spinner__circle-gradient"></div>

                <div class="spinner__circle-inner"></div>
              </div>
              <span class="ms-2 text"> Een momentje...</span>
            </div>
          </div>

          <app-deepdive-notes *ngIf="!loader" [type]="link.noteType" [notesDetails]="notesSectionDetails"
            [customerID]="customerId" [breakEvenGoal]="selectedBEGoal"
            [selectedType]="notesTabSelected"></app-deepdive-notes>
          <app-error [errorType]="'consultant'"></app-error>
        </div>
      </ng-template>
    </li>
  </ul>
</div>

<ng-container>
  <div class="notes-content-container">
    <div class="left-layout">
      <div [ngbNavOutlet]="notesNav" class="mt-4 pt-3"></div>
    </div>
    <div class="right-layout">
      <ng-container *ngIf="notesTabSelected.noteType == 'ACTIONS' || notesTabSelected.noteType == 'GOALS'"> 

   
      <ng-container *ngIf="recommendationList?.COMPANIES_WITH_SAME_SECTOR?.length">
        <p class="deepdive-notes-title">
          Klanten in dezelfde (sub)-sector
        </p>
        <div class="company-container">
          <p *ngFor="let company of recommendationList?.COMPANIES_WITH_SAME_SECTOR" class="regulation-border" (click)="onCompanyNameClick(company)">
            <a class="company-name">
              {{company?.COMPANY_NAME}}
            </a>
          </p>
        </div>
 
      </ng-container>
      <ng-container *ngIf="recommendationList?.COMPANIES_WITH_SAME_ENERGY_USE?.length">
        <p class="deepdive-notes-title">
          Klanten met vergelijkbaar energiegebruik
        </p>
        <div class="company-container">
        <p *ngFor="let company of recommendationList?.COMPANIES_WITH_SAME_ENERGY_USE" class="regulation-border" (click)="onCompanyNameClick(company)">
          <span class="company-name">
            {{company?.COMPANY_NAME}}
          </span>
        </p>
        </div>
      </ng-container>
      <ng-container *ngIf="recommendationList?.OTHER_COMPANIES?.length">
        <p class="deepdive-notes-title">
          Andere klanten
        </p>
        <div class="company-container">
        <p *ngFor="let company of recommendationList?.OTHER_COMPANIES" class="regulation-border" (click)="onCompanyNameClick(company)">
          <span class="company-name">
            {{company?.COMPANY_NAME}}
          </span>
        </p>
        </div>
      </ng-container>
    </ng-container>
    </div>
  </div>
</ng-container>