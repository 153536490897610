import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilService } from 'src/app/shared/services/util.service';
import { SORT_ORDER, sortAlphabetically, 
  getCursorClass, getStatusHeading, getStatusClass, 
  getIconSource, openNewTab, getStatus, getDeepDiveStateName } from './utils';
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})

export class TableComponent implements OnInit {
  @Input() tableData!: any[];
  @Input() tableHeadings!: any[];
  @Output() selectedClient = new EventEmitter<any>();
  currentStates: any;
  sortingOrder: any = SORT_ORDER.ASC;
  lastSortedState: any = 'COMPANY_NAME';
  getCursorClass: any = getCursorClass;
  getStatusClass: any = getStatusClass;
  getIconSource: any = getIconSource;
  openNewTab: any = openNewTab;
  getStatus: any = getStatus;
  getDeepDiveStateName: any = getDeepDiveStateName;

  constructor(public utilService: UtilService) { }

  ngOnInit(): void {
    this.calculateStatusForTableRow();
    this.toggleSorting('COMPANY_NAME')
  }

  toggleSorting(key: string) {
    this.sortingOrder = (this.sortingOrder === SORT_ORDER.DES && this.lastSortedState === key) ? SORT_ORDER.ASC : SORT_ORDER.DES;
    const modifiedKey = key === 'STATE' ? 'statusHeading' : key;
    this.tableData = sortAlphabetically({
      data: this.tableData,
      key: modifiedKey,
      sortingOrder: this.sortingOrder,
    })
    this.lastSortedState = key;
  }

  calculateStatusForTableRow() {
    this.tableData?.forEach((row) => {
      const riskScanState = {
        stateName: "Risicoscan",
        status: this.getStatus(row.STATE)
      }

      let deepDiveStates = row.DEEPDIVE_STATES?.map((deepDiveState: any) => {
        const ddState = {
          id: deepDiveState.BREAK_EVEN_GOAL_ID,
          stateName: this.getDeepDiveStateName(deepDiveState.BREAK_EVEN_GOAL_ID),
          status: this.getStatus(deepDiveState.DEEPDIVE_STATE)
        };
        return ddState;
      })

      if (deepDiveStates?.length) {
        row.statusHeading = getStatusHeading([riskScanState, ...deepDiveStates]);
        row.currentStates = [riskScanState, ...deepDiveStates?.sort((a: any, b: any) => a.id - b.id)];
      } else {
        row.statusHeading = riskScanState?.status;
        row.currentStates = [riskScanState]
      }

    })
  }

  getValue(value: any, header: any) {
    //to display the date in the required format
    if (header?.dateFormat) {
      let date = this.utilService.formatDate(value, header.dateFormat);
      return date;
    }
    if (header?.key === 'KVK_NUMBER') {
      let kvk_number = this.utilService.formatKvkNumber(value);
      return kvk_number;
    }
    return value;
  }

  toggleRow(index: number, row: any) {
    this.tableData[index].collapsed = !this.tableData[index].collapsed;
  }

  /**
   * Selected Row
   */
  selectedRow(row: any) {
    this.tableData.forEach((row: any) => delete row?.isSelected);
    row.isSelected = true;
    this.selectedClient.emit(row);

  }
}
