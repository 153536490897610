const REGEX = {
  EMAIL:
    /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
  NUMBER: /^[+]?[0-9]*$/,
  KVK_NUMBER: /^\d{4}\s\d{4}$/,
};

const GROUPS_LENGTH = 15;

const CLIENTS_TABLE_HEADER_COLUMNS = [
  {
    label: 'Bedrijfsnaam',
    displayLabel: true,
    name: 'Bedrijfsnaam',
    key: 'COMPANY_NAME',
    fieldType: 'text',
    align: 'left',
    sorting: true,
    defaultSortBy: 'desc',
  },
  {
    label: 'Toegewezen aan',
    displayLabel: true,
    name: 'ASSIGNED_CONSULTANT',
    key: 'ASSIGNED_CONSULTANT',
    fieldType: 'text',
    align: 'left',
    sorting: true,
    defaultSortBy: 'desc',
  },
  {
    label: 'Toegevoegd op',
    displayLabel: true,
    name: 'Toegevoegd op',
    key: 'CREATION_DATE',
    fieldType: 'text',
    align: 'left',
    sorting: true,
    defaultSortBy: 'desc',
    dateFormat: 'dd-MM-yy',
  },
  {
    label: 'Kring',
    displayLabel: true,
    name: 'REGION',
    key: 'REGION',
    fieldType: 'text',
    align: 'left',
    sorting: true,
    defaultSortBy: 'desc',
  },
  {
    label: 'Status',
    displayLabel: true,
    name: 'Status',
    key: 'STATE',
    fieldType: 'text',
    align: 'left',
    sorting: true,
    defaultSortBy: 'desc',
    getIconPath: true,
  },
];

const SELECTED_CLIENT_TABS_LINKS = [
  { title: 'Contact', route: 'edit', disabled: false },
  { title: 'Risico scan', route: 'riskScan', disabled: true },
  { title: 'Assessments', route: 'assessments', disabled: false },
];

const ENERGIE_DEEPDIVE_QUESTIONS_TABS = [
  {
    groupId: 1,
    title: 'Elektriciteit',
    key: 'electricity',
    disabled: false,
    saved: false,
  },
  { groupId: 2, title: 'Aardgas', key: 'gas', disabled: false, saved: false },
  {
    groupId: 3,
    title: 'Brandstof (wagenpark)',
    key: 'fuel',
    disabled: false,
    saved: false,
  },
  {
    groupId: 4,
    title: 'Vastgoed',
    key: 'property',
    disabled: false,
    saved: false,
  },
  {
    groupId: 5,
    title: 'Overige enerige',
    key: 'other-energy',
    disabled: false,
    saved: false,
  },
  {
    groupId: 6,
    title: 'Broeikasgassen',
    key: 'greenhouse-gas',
    disabled: false,
    saved: false,
  },
  {
    groupId: 7,
    title: 'Strategie en management',
    key: 'strategy-management',
    disabled: false,
    saved: false,
  },
];

const SELECTED_CLIENT_MENU = [
  {
    label: 'Wijzigen',
    dataKey: 'edit',
    disabled: false,
  },
  {
    label: 'Verwijderen',
    dataKey: 'delete',
    disabled: false,
  },
];

const SUSTAINABLE_DEVELOPMENT_GOALS = [
  { ID: 1, color: '#D73837' },
  { ID: 2, color: '#C59D3B' },
  { ID: 3, color: '#4D9951' },
  { ID: 4, color: '#B33239' },
  { ID: 5, color: '#DC4F3A' },
  { ID: 6, color: '#4CABD5' },
  { ID: 7, color: '#F2BA43' },
  { ID: 8, color: '#832439' },
  { ID: 9, color: '#E2753C' },
  { ID: 10, color: '#CE2F83' },
  { ID: 11, color: '#ECA144' },
  { ID: 12, color: '#C59040' },
  { ID: 13, color: '#527643' },
  { ID: 14, color: '#367BB8' },
  { ID: 15, color: '#60AE56' },
  { ID: 16, color: '#235488' },
  { ID: 17, color: '#243462' },
];

const BE_GOALS = [
  {
    ID: 1,
    BREAK_EVEN_GOAL_ID: 1,
    BREAK_EVEN_GOAL_TITLE: 'Energie',
    TITLE: 'Energie',
    UNIT: '%',
    MAX_PERFORMACE_NOTE_SECTIONS: 2,
    MAX_GOALS_NOTE_SECTIONS: 1,
    CHART_TYPE: 'PIE',
    ICON: '../../../assets/images/Energie.svg',
    DEEPDIVE_STATE: 'DEEPDIVE_NOT_STARTED',
  },
  {
    ID: 2,
    BREAK_EVEN_GOAL_ID: 2,
    TITLE: 'Water',
    BREAK_EVEN_GOAL_TITLE: 'Water',
    MAX_PERFORMACE_NOTE_SECTIONS: 3,
    MAX_GOALS_NOTE_SECTIONS: 2,
    CHART_TYPE: 'BAR',
    UNIT: 'L',
    ICON: '../../../assets/images/Water.svg',
    DEEPDIVE_STATE: 'DEEPDIVE_NOT_STARTED',
  },
  {
    ID: 3,
    TITLE: 'Natuurlijke hulpbonnen',
  },
  {
    ID: 4,
    TITLE: 'Inkoop',
  },
  {
    ID: 5,
    TITLE: 'Overige operationele emissies',
  },
  {
    ID: 6,
    BREAK_EVEN_GOAL_ID: 6,
    TITLE: "Broeikasgasemissies (BKG's)",
    BREAK_EVEN_GOAL_TITLE: "Broeikasgasemissies (BKG's)",
    UNIT: 'kg',
    MAX_PERFORMACE_NOTE_SECTIONS: 1,
    MAX_GOALS_NOTE_SECTIONS: 1,
    CHART_TYPE: 'BAR',
    ICON: `../../../assets/images/Broeikasgasemissies (BKG's).svg`,
    DEEPDIVE_STATE: 'DEEPDIVE_NOT_STARTED',
  },
  {
    ID: 7,
    BREAK_EVEN_GOAL_ID: 7,
    TITLE: 'Afval',
    BREAK_EVEN_GOAL_TITLE: 'Afval',
    MAX_PERFORMACE_NOTE_SECTIONS: 1,
    MAX_GOALS_NOTE_SECTIONS: 1,
    CHART_TYPE: 'BAR',
    UNIT: 'kg',
    ICON: '../../../assets/images/Afval.svg',

    DEEPDIVE_STATE: 'DEEPDIVE_NOT_STARTED',
  },
  {
    ID: 8,
    TITLE: 'Fysieke aanwezigheid',
  },
  {
    ID: 9,
    TITLE: 'Maatschappelijke betrokkenheid',
  },
  {
    ID: 10,
    TITLE: 'Gezondheid medewerkers',
  },
  {
    ID: 11,
    TITLE: 'Leefbaar loon',
  },
  {
    ID: 12,
    TITLE: 'Eerlijke arbeidsvoorwaarden',
  },
  {
    ID: 13,
    TITLE: 'Discriminatie medewerkers',
  },
  {
    ID: 14,
    TITLE: 'Betrokkenheid medewerkers',
  },
  {
    ID: 15,
    TITLE: 'Product communicatie',
  },
  {
    ID: 16,
    TITLE: 'Klantbetrokkenheid',
  },
  {
    ID: 17,
    TITLE: 'Producteigenschappen',
  },
  {
    ID: 18,
    TITLE: 'Productgerelateerde BKG emissies',
  },
  {
    ID: 19,
    TITLE: 'Producthergebruik',
  },
  {
    ID: 20,
    TITLE: 'Bedrijfsethiek',
  },
  {
    ID: 21,
    TITLE: 'Belastingen',
  },
  {
    ID: 22,
    TITLE: 'Pleitbezorging en Lobby',
  },
  {
    ID: 23,
    TITLE: 'Financiële Activa',
  },
];

const RISK_LEVELS = [
  {
    key: 'HIGH',
  },
  {
    key: 'MODERATE',
  },
  {
    key: 'LOW',
  },
];

const COMMENT_MENU_OPTIONS = [
  {
    label: 'Wijzigen',
    dataKey: 'edit',
    disabled: false,
  },
  {
    label: 'Verwijderen',
    dataKey: 'delete',
    disabled: false,
  },
];

const DEEPDIVE_CONSULTANT_TABS = [
  { title: 'Resultaten', route: 'results', disabled: false },
  { title: 'Notities', route: 'comments', disabled: true },
];

const CONSULTANT_ASSESSMENT_NOTES_TABS = [
  {
    commentGroupId: 1,
    title: 'Huidige prestaties',
    key: 'deepdivePerformance',
    noteType: 'PERFORMANCE',
    TEXT: '',
    disabled: false,
    saved: false,
    subTitles: [
      {
        sectionId: 1,
        subTitle: 'Hernieuwbare energie',
        key: 'hernieuwbareEnergie',
        loader: false,
      },
      {
        sectionId: 2,
        subTitle: 'Energieverbruik',
        key: 'energieverbruik',
        loader: false,
      },
    ],
  },
  {
    commentGroupId: 2,
    title: 'Waarom het anders moet',
    key: 'deepdiveRegulations',
    noteType: 'REGULATIONS',
    TEXT: '',
    disabled: false,
    saved: false,
  },
  {
    commentGroupId: 3,
    title: 'Uw voorgestelde doel',
    key: 'deepdiveGoal',
    noteType: 'GOALS',
    TEXT: '',
    disabled: false,
    saved: false,
    subTitles: [
      {
        sectionId: 1,
        subTitle: 'Toelichting doel',
        key: 'goalText',
        loader: false,
      },
    ],
  },
  {
    commentGroupId: 4,
    title: 'Acties die u kunt nemen',
    key: 'deepdiveAction',
    noteType: 'ACTIONS',
    TEXT: '',
    disabled: false,
    saved: false,
  },
];

const DEEPDIVE_PERFORMANCE_SECTIONS = [
  {
    sectionId: 1,
    title: 'Hernieuwbare energie',
    key: 'hernieuwbareEnergie',
    loader: false,
    TEXT: '',
  },
  {
    sectionId: 2,
    title: 'Energieverbruik',
    key: 'energieverbruik',
    loader: false,
    TEXT: '',
  },
];

const PIE_CHART_LABELS = [
  {
    LABEL: 'ENERGY',
    DUTCH_LABEL: 'Elektriciteit',
    COLOR: '#141240',
    BACKGROUND_COLOR: '#FFBC1F',
  },
  {
    LABEL: 'GAS',
    DUTCH_LABEL: 'Aardgas',
    COLOR: '#FFFFFF',
    BACKGROUND_COLOR: '#0089FF',
  },
  {
    LABEL: 'FUEL',
    DUTCH_LABEL: 'Brandstof',
    COLOR: '#141240',
    BACKGROUND_COLOR: '#CCCCCC',
  },
  {
    LABEL: 'NONRENEWABLE',
    DUTCH_LABEL: 'Niet hernieuwbaar',
    COLOR: '#141240',
    BACKGROUND_COLOR: '#E0E0E1',
  },
  {
    LABEL: 'RENEWABLE',
    DUTCH_LABEL: 'Hernieuwbaar',
    COLOR: '#FFFFFF',
    BACKGROUND_COLOR: '#FF7523',
  },
];

const REDUCTION_CHART_LABELS = [
  {
    LABEL: '2021',
    BACKGROUND_COLOR: '#FF7523',
  },
  {
    LABEL: '2022',
    BACKGROUND_COLOR: '#FF7523',
  },
  {
    LABEL: '2030',
    BACKGROUND_COLOR: '#E0E0E1' ,
  },
]

export {
  REGEX,
  CLIENTS_TABLE_HEADER_COLUMNS,
  GROUPS_LENGTH,
  SELECTED_CLIENT_TABS_LINKS,
  SELECTED_CLIENT_MENU,
  BE_GOALS,
  RISK_LEVELS,
  SUSTAINABLE_DEVELOPMENT_GOALS,
  COMMENT_MENU_OPTIONS,
  ENERGIE_DEEPDIVE_QUESTIONS_TABS,
  DEEPDIVE_CONSULTANT_TABS,
  CONSULTANT_ASSESSMENT_NOTES_TABS,
  DEEPDIVE_PERFORMANCE_SECTIONS,
  PIE_CHART_LABELS,
  REDUCTION_CHART_LABELS,
};
