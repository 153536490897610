import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  NgbCalendar,
  NgbDateAdapter,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { every, find, set } from 'lodash';
import { Observable, take } from 'rxjs';
import { UtilService } from 'src/app/shared/services/util.service';
import { DeepdiveAssessmentActions } from 'src/app/store/actions';
import { IAppState } from 'src/app/store/state';
import { deepdiveGroup8 } from 'src/app/shared/deepdive-group8-init';

@Component({
  selector: 'app-deepdive-questions',
  templateUrl: './deepdive-questions.component.html',
  styleUrls: ['./deepdive-questions.component.scss'],
})
export class DeepdiveQuestionsComponent implements OnInit, OnChanges {
  @Input() deepDiveQuestions!: any[];
  @Input() selectedTab!: any;
  @Input() groupDeepDiveDetails: any = {};
  @Input() tabsLength!: number;
  @Input() showDescription: boolean = true;
  @Input() deepdiveStatus!: any;
  @Input() refYear: any;

  storeValue: any;
  questionsForm!: FormGroup;
  showLoader: boolean = false;
  formBuilder = inject(FormBuilder);
  utilService = inject(UtilService);
  private ngbCalendar = inject(NgbCalendar);
  private dateAdapter = inject(NgbDateAdapter);
  private dateFormatter = inject(NgbDateParserFormatter);
  @Output() onSelectingPreviousTab = new EventEmitter<any>();
  @Output() onSubmittingGroupAnswers = new EventEmitter<any>();
  state!: Observable<IAppState>;
  allDependentQuestionsHidden: boolean = true;

  private cdRef = inject(ChangeDetectorRef);

  constructor(private store: Store<IAppState>) { }

  ngOnInit(): void {
    this.store.subscribe((state) => {
      this.storeValue = state;
    });

    this.initForm();
    // parseInt(this.storeValue?.deepdiveAnswerState?.groupId) ===
    // parseInt(this.selectedTab?.groupId)
    //   ? this.initForm(this.storeValue?.deepdiveAnswerState?.deepdiveAnswers)
    //   : this.initForm();
    this.calculateAllDependentQuestionsHidden();
    this.parseRefYear();
  }

  parseRefYear() {
    const searchRegExp = /<ref-year>/gi;
    const dDQuestions = JSON.stringify(this.deepDiveQuestions);
    const result = dDQuestions.replace(searchRegExp, this.refYear);
    this.deepDiveQuestions = JSON.parse(result);
  }

  get today() {
    return this.dateFormatter.format(this.ngbCalendar.getToday())!;
  }

  ngOnChanges() {
    this.deepDiveQuestions = this.deepDiveQuestions;
    this.initForm();
  }

  returnControl(array: any, label: any, value: any) {
    array?.map(
      (eachLabelValue: any) =>
        this.formBuilder.control(
          eachLabelValue?.ANSWER
            ? eachLabelValue?.LABEL === label
              ? eachLabelValue?.ANSWER
              : ''
            : ''
        ),
      [Validators.max(value?.MAX_VALUE), Validators.min(value?.MIN_VALUE)]
    );
  }

  initForm() {
    const deepDiveQuestions = this.deepDiveQuestions;
    this.questionsForm = this.formBuilder.group({
      array: this.formBuilder.array(
        deepDiveQuestions.map((value: any) =>
          this.formBuilder.group({
            ID: this.formBuilder.control(value?.ID ?? ''),
            TEXT: this.formBuilder.control(value?.TEXT ?? ''),
            TYPE: this.formBuilder.control(value?.TYPE ?? ''),
            answerlabel: this.buildAnswerLabel(value),
            DISABLED: this.formBuilder.control(value?.DISABLED ?? ''),
            UNIT: this.formBuilder.control(value?.UNIT ?? ''),
            ANSWERS: this.formBuilder.control(
              value?.TYPE === 'DATE'
                ? this.utilService.toDate(
                  value?.ANSWER?.ANSWERS?.[0]?.ANSWER
                ) ?? null
                : value?.ANSWER?.ANSWERS?.[0]?.ANSWER ?? '',
              [
                value?.TYPE === 'TEXT' || value?.TYPE === 'TEXT_AREA'
                  ? Validators.maxLength(value?.MAX_VALUE)
                  : Validators.max(value?.MAX_VALUE),
                value?.TYPE === 'TEXT' || value?.TYPE === 'TEXT_AREA'
                  ? Validators.minLength(value?.MIN_VALUE)
                  : Validators.min(value?.MIN_VALUE),
              ]
            ),
          })
        )
      ),
    });

    if (this.deepdiveStatus === 'DEEPDIVE_SUBMITTED') {
      this.questionsForm?.disable();
      return;
    }

    this.questionsForm.valueChanges.subscribe((res) => {
      const formArray: FormArray = this.questionsForm.get('array') as FormArray;
      this.store.dispatch(
        DeepdiveAssessmentActions.saveGroupDeepDiveAssessmentAnswers({
          value: formArray?.value,
          groupId: this.selectedTab?.ID,
        })
      );
    });
  }

  buildAnswerLabel(value: any) {
    if (value?.TYPE === 'MULTI_NUMBER') {
      return this.formBuilder.array(value?.ANSWER_LABELS?.map((eachValue: any) =>
        this.formBuilder.group({
          LABEL: this.formBuilder.control(eachValue ?? ''),
          ANSWER: this.formBuilder.control(
            find(value?.ANSWER?.ANSWERS, ['LABEL', eachValue])?.ANSWER ?? '',
            [
              Validators.max(value?.MAX_VALUE),
              Validators.min(value?.MIN_VALUE),
            ]
          ),
        })
      ))
    } else if (value?.TYPE === 'CHECKBOX') {
      return this.formBuilder.array(value?.ANSWER_LABELS?.map((eachValue: any) =>
        this.formBuilder.group({
          LABEL: this.formBuilder.control(eachValue ?? ''),
          ANSWER: this.formBuilder.control(
            find(value?.ANSWER?.ANSWERS, ['LABEL', eachValue])?.ANSWER === 'true' ?? '',
          ),
        })
      ))
    } else {
      return false;
    }
  }

  getArrayControls() {
    return (this.questionsForm.get('array') as FormArray).controls;
  }

  getAnswerLabelControls(index: any) {
    return (
      (this.questionsForm.get('array') as FormArray)
      ['at'](index)
        .get('answerlabel') as FormArray
    ).controls;
  }

  getQuestionsFormArray() {
    const formArray: FormArray = this.questionsForm.get('array') as FormArray;
    return formArray?.value;
  }

  clearDate(index: number, controlName: any) {
    this.getArrayControls()?.at(index)?.get(controlName)?.reset();
    this.getArrayControls()?.at(index)?.get(controlName)?.setValue('');
  }

  backSpace(event: any, index: number, controlName: any) {
    if (event.keyCode == 8 || event.keyCode == 46) {
      this.clearDate(index, controlName);
    }
  }

  getPreviousTab() {
    this.onSelectingPreviousTab.emit();
  }

  //Destroying the toast
  ngOnDestroy() {
    const formArray: FormArray = this.questionsForm?.get('array') as FormArray;
    formArray.clear();
  }

  //Disabling or enabling the dependent questions based on radio button
  onAnsweringRadioButton(relatedQuestionArray: any, answer: any) {
    this.deepDiveQuestions.map((question, index) => {
      relatedQuestionArray.filter((id: any) => {
        if (Number(id) === Number(question?.ID)) {
          question.DISABLED = answer === 'Ja' ? false : true;
          //Disabling the answers for the dependent question if the answer is NO
          this.getArrayControls()
            ?.at(index)
            ?.get('DISABLED')
            ?.setValue(question.DISABLED);
          //clearing the answers for the dependent question if the answer is NO
          if (question?.TYPE === 'MULTI_NUMBER') {
            question?.ANSWER?.ANSWERS?.map((eachValue: any, i: any) => {
              this.getAnswerLabelControls(index)[i].get('ANSWER')?.reset();
              this.getAnswerLabelControls(index)[i].get('ANSWER')?.setValue('');
            });
          }
          this.getArrayControls()?.at(index)?.get('ANSWERS')?.reset();
          this.getArrayControls()?.at(index)?.get('ANSWERS')?.setValue('');
        }
      });
    });
  }

  onAnsweringCheckbox(value: any, idx: any) {
    let currentAnswers = value?.ANSWER?.ANSWERS;
    if (currentAnswers == undefined) {
      let currentAnswer = deepdiveGroup8.find((element: { ID: string; }) => element.ID === value.ID)
      currentAnswers = currentAnswer?.ANSWER?.ANSWERS;
    }
    currentAnswers[idx].ANSWER = currentAnswers[idx].ANSWER === 'true' ? 'false' : 'true';

    this.deepDiveQuestions.map((question, index) => {
      value?.RELATED_QUESTIONS?.filter((id: any) => {
        if (Number(id) === Number(question?.ID)) {
          question.DISABLED = !this.calculateShouldShowDependentQuestions(currentAnswers);
          //Disabling the answers for the dependent question if a checkbox is checked
          this.getArrayControls()
            ?.at(index)
            ?.get('DISABLED')
            ?.setValue(question.DISABLED);
          this.getArrayControls()?.at(index)?.get('ANSWERS')?.reset();
          this.getArrayControls()?.at(index)?.get('ANSWERS')?.setValue('');
        }
      });
    });

    this.calculateAllDependentQuestionsHidden();
  }

  calculateShouldShowDependentQuestions(currentAnswers: any,) {
    return currentAnswers.find((element: { ANSWER: string; }) => element.ANSWER === 'true') ? true : false;
  }

  calculateAllDependentQuestionsHidden() {
    this.allDependentQuestionsHidden = this.deepDiveQuestions.find((value: { DISABLED: boolean; ID: string; }) => value.DISABLED == false && Number(value.ID) > 55 && Number(value.ID) < 68) ? false : true;
  }

  getChartData(deepdiveChartData: any) {
    return {
      data: deepdiveChartData?.VALUES.map((chart: any) => chart?.VALUE),
      labels: deepdiveChartData?.VALUES.map((chart: any) => chart?.LABEL),
      chartType:
        deepdiveChartData?.TYPE === 'PIE'
          ? 'doughnut'
          : deepdiveChartData?.TYPE === 'BAR'
            ? 'bar'
            : 'stackedBar',
    };
  }
}
