import { Component, inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { cloneDeep, range, set } from 'lodash';
import { ConsultantService } from 'src/app/shared/services/consultant.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { UtilService } from 'src/app/shared/services/util.service';
import {
  CONSULTANT_ASSESSMENT_NOTES_TABS,
  DEEPDIVE_PERFORMANCE_SECTIONS,
} from 'src/app/shared/utilities/constants';


@Component({
  selector: 'app-assessment-comments-tab',
  templateUrl: './assessment-comments-tab.component.html',
  styleUrls: ['./assessment-comments-tab.component.scss'],
})
export class AssessmentCommentsTabComponent implements OnInit {
  customerId!: any;
  notesTabSelected: any;
  notesSectionDetails: any;
  loader: boolean = false;
  commentTabs: any = cloneDeep(CONSULTANT_ASSESSMENT_NOTES_TABS);
  @Input() selectedBEGoal: any;
  private activatedRoute = inject(ActivatedRoute);
  private consultantService = inject(ConsultantService);
  public errorService = inject(ErrorService);
  public loaderService = inject(LoaderService);
  public utilService = inject(UtilService);
  recommendationList: any;

  maxTextEditors: any = [1, 2];

  constructor() {}

  ngOnInit(): void {
    this.customerId =
      this.activatedRoute?.parent?.snapshot?.paramMap?.get('id');
    this.onSelectedTab(this.commentTabs[0]);
  }

  onSelectedTab(tab: any) {
    this.notesTabSelected = tab;
    this.fetchNoteSections(tab);
    if (tab?.noteType === 'ACTIONS' || tab?.noteType === "GOALS" && !this.recommendationList) {
      this.fetchNoteRecommendations();
    }
  }

  onCompanyNameClick(company: any){
    const baseUrl = window.location.origin;
    const redirectUrl = `${baseUrl}/consultants/preview-dashboard?customerId=${company?.ID}&beGoalID=${company?.BREAK_EVEN_GOAL_ID}`
    window.open(redirectUrl, "_blank");
  }

  getPerformanceText(notes: any) {
    if (notes?.length === 2) {
      notes.map((note: any) =>
        Number(note?.ID) === 1
          ? set(note, 'TITLE', 'Hernieuwbare energie')
          : set(note, 'TITLE', 'Energieverbruik')
      );
    } else {
      const missedID = this.maxTextEditors.filter((total: any) =>
        notes.every((note: any) => parseInt(note.ID) !== total)
      );
      notes.map((note: any) =>
        Number(note?.ID) === 1
          ? set(note, 'TITLE', 'Hernieuwbare energie')
          : set(note, 'TITLE', 'Energieverbruik')
      );
      const extraObject = {
        ID: missedID[0],
        TEXT: '',
        TITLE: missedID[0] === 1 ? 'Hernieuwbare energie' : 'Energieverbruik',
      };
      notes = [...notes, extraObject];
    }
    return notes;
  }

  /**
   * Fetching the Deepdive Notes
   */
  fetchNoteSections(tab: any) {
    this.errorService.hide();
    this.loader = true;
    this.consultantService
      .getDeepdiveNotesForConsultant(
        this.customerId,
        this.selectedBEGoal?.ID,
        tab?.noteType
      )
      .subscribe({
        next: (response: any) => {
          this.notesSectionDetails =
            response?.length > 0
              ? response
              : tab?.commentGroupId === 1
                ? this.utilService.getDeepdiveMaxNotePerformanceSections(this.selectedBEGoal?.TITLE)
                : tab?.commentGroupId === 3
                  ? this.utilService.getDeepdiveMaxNoteGoalsSections(this.selectedBEGoal?.TITLE)
                  : [1];
        },
        error: (error) => {
          // handle error
          this.loader = false;
          this.errorService.show();
        },
        complete: () => {
          //Request completed
          this.loader = false;
        },
      });
  }
  
  /**
   * Fetching the Deepdive Notes Recommendations
   */
  fetchNoteRecommendations() {
    this.errorService.hide();
    this.loader = true;
    this.consultantService
      .getDeepdiveNotesRecommendations(
        this.customerId,
        this.selectedBEGoal?.ID,
      )
      .subscribe({
        next: (response: any) => {
          this.recommendationList = response;
        },
        error: (error) => {
          // handle error
          this.loader = false;
          this.errorService.show();
        },
        complete: () => {
          //Request completed
          this.loader = false;
        },
      });
  }
}
