<div
  class="begoalslist-layout content-container d-flex flex-column"
  [ngClass]="{
    boxShadow: applyBoxShadow
  }"
>
  <div class="text">
    <label class="subLabel">Break Even Goals</label>
    <p class="description">
      Selecteer de assessments die de klant heeft afgenomen. Wanneer je geen
      assessments selecteert zal de klant alleen de risicoscan zien. Je kunt ook
      assessments deselecteren maar alleen zolang de klant nog niet gestart is
      met dit assessment.
    </p>
    <p class="description">
      {{
        !updatedBEGoalsList?.length
          ? "Er is nog geen assessment geselecteerd."
          : "Er zijn " +
          updatedBEGoalsList?.length +
            " assessment(s) geselecteerd."
      }}
    </p>
  </div>

  <checkbox-group
    [applyBoxShadow]="applyBoxShadow"
    (onToggleItem)="onToggleItem($event)"
    [itemsList]="BEGoalsList"
  ></checkbox-group>
</div>
