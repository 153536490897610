import { cloneDeep } from "lodash";
import { BE_GOALS } from "src/app/shared/utilities/constants";

export const arrayToObject = ((DataArray: any, KEY: any, VALUE:any) => {
  const regionObj: any = {};
  for (let i = 0; i < DataArray.length; i++) {
    const dataKey = DataArray[i][KEY];
    const dataValue = DataArray[i][VALUE];
    regionObj[dataKey] = dataValue;
  };
  return regionObj;
});

export function cleanObject(obj: any): any {
  const allowedKeys = [
    'BREAK_EVEN_GOAL_ID',
    'BREAK_EVEN_GOAL_TITLE',
    'DEEPDIVE_STATE',
  ];

  const cleanedObj: any = {};

  for (const key in obj) {
    if (allowedKeys.includes(key)) {
      cleanedObj[key] = obj[key];
    }
  }

  return cleanedObj;
}

export function transformArrayToObject(array: any[]): any {
  return array.reduce((result: any, item: any) => {
    const { ID, ...rest } = item;
    result[ID] = rest;
    return result;
  }, {});
}

export const BE_GOALS_PAIR = {
  1: {
    ID: 1,
    TITLE: 'Energie',
    UNIT: '%',
    MAX_PERFORMACE_NOTE_SECTIONS: 2,
    MAX_GOALS_NOTE_SECTIONS: 1,
    CHART_TYPE: 'PIE',
    ICON: '../../../assets/images/Energie.svg',
    DEEPDIVE_STATE: 'DEEPDIVE_NOT_STARTED',
  },
  6: {
    ID: 6,
    TITLE: "Broeikasgasemissies (BKG's)",
    UNIT: 'Kg',
    MAX_PERFORMACE_NOTE_SECTIONS: 1,
    MAX_GOALS_NOTE_SECTIONS: 1,
    CHART_TYPE: 'BAR',
    ICON: `../../../assets/images/Broeikasgasemissies (BKG's).svg`,
    DEEPDIVE_STATE: 'DEEPDIVE_NOT_STARTED',
  },
};

export function BE_GOALS_LIST(): any {
  return cloneDeep(
    BE_GOALS.filter((listItem: any) => [1, 6, 2, 7].includes(listItem.ID))
  );
}
