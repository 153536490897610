<!-- <p *ngIf="(editorSubject | async) === null">Een momentje..</p> -->
<form [formGroup]="editorFormGroup">
  <editor [init]="settings" [id]="id" (onInit)="onEditorInit($event)" [formControlName]="controlName"
    [class.error]="forbiddenContent?.isError"></editor>
  <div class="error-message" *ngIf="forbiddenContent?.isError">
    <p>
      <img [src]="'../../../assets/images/danger-info.svg'" alt="forbidden word info" />
      <span>
        Het woord {{forbiddenContent?.markedWords}} kan niet worden gebruikt omdat we geen advies mogen geven. Kies een andere bewoording. 
      </span>
    </p>
  </div>
</form>