import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { ErrorInterceptor } from 'src/app/auth/inteceptors/error.interceptor';
import { httpUrls as apiUrl } from '../models/httpUrls';

@Injectable({
  providedIn: 'root',
})
export class ConsultantService {
  constructor(private http: HttpClient) {}

  public handleError(error: ErrorInterceptor) {
    return throwError(error);
  }

  //Fetching sector, subsector and business activity tree
  getSector() {
    return this.http.get(apiUrl.SECTOR).pipe(catchError(this.handleError));
  }

  //Create client
  createCustomer(reqBody?: any) {
    return this.http
      .post(apiUrl.CUSTOMER, reqBody)
      .pipe(catchError(this.handleError));
  }

  //get customers list
  getCustomers() {
    return this.http.get(apiUrl.CUSTOMERS).pipe(catchError(this.handleError));
  }

  //get consultants list
  getConsultants() {
    return this.http.get(apiUrl.CONSULTANTS).pipe(catchError(this.handleError));
  }

  //fetching the individual client details
  getCustomerDetils(customerId: any) {
    return this.http
      .get(`${apiUrl.GET_CUSTOMER_DETAILS.replace('{customerId}', customerId)}`)
      .pipe(catchError(this.handleError));
  }

  //update customer
  updateCustomer(reqBody?: any) {
    return this.http
      .put(apiUrl.CUSTOMER, reqBody)
      .pipe(catchError(this.handleError));
  }

  //get risk scan be goals
  getRiskScanGoals(customerId: any) {
    return this.http
      .get(
        `${apiUrl.GET_RISK_SCAN_BE_GOALS.replace('{customerId}', customerId)}`
      )
      .pipe(catchError(this.handleError));
  }

  postCustomerStatusByConsultant(customerId: any, eventId: any) {
    const url = `${apiUrl.POST_CUSTOMER_STATUS.replace(
      '{customerId}',
      customerId
    ).replace('{eventId}', eventId)}`;
    return this.http.post(url, {}).pipe(catchError(this.handleError));
  }

  getClientBEGoalDetails(customerId: any, breakEvenGoalId: any) {
    const url = `${apiUrl.GET_CUSTOMER_BE_GOAL_DETAILS.replace(
      '{customerId}',
      customerId
    ).replace('{breakEvenGoalId}', breakEvenGoalId)}`;
    return this.http.get(url).pipe(catchError(this.handleError));
  }

  addComments(customerId: any, breakEvenGoalId: any, comment: any) {
    const url = `${apiUrl.RISK_SCAN_NOTES.replace(
      '{customerId}',
      customerId
    ).replace('{breakEvenGoalId}', breakEvenGoalId)}`;
    return this.http.put(url, comment).pipe(catchError(this.handleError));
  }

  deleteComments(customerId: any, breakEvenGoalId: any, comment: any) {
    const url = `${apiUrl.RISK_SCAN_NOTES.replace(
      '{customerId}',
      customerId
    ).replace('{breakEvenGoalId}', breakEvenGoalId)}`;
    return this.http.delete(url, comment).pipe(catchError(this.handleError));
  }

  changeRiskscanAnswers(customerId: any, reqBody?: any) {
    return this.http
      .put(
        `${apiUrl.CHANGE_RISKSCAN_ANSWERS.replace('{customerId}', customerId)}`,
        reqBody
      )
      .pipe(catchError(this.handleError));
  }

  getConsultantCustomerDeppdiveOverview(customerId: any) {
    return this.http
      .get(
        `${apiUrl.CONSULTANT_CUSTOMER_DEEPDIVE_OVERVIEW.replace(
          '{customerId}',
          customerId
        )}`
      )
      .pipe(catchError(this.handleError));
  }

  getConsultantCustomerDeppdiveDetails(
    customerId: any,
    groupId: any,
    breakEvenGoalId: any
  ) {
    return this.http
      .get(
        `${apiUrl.CONSULTANT_CUSTOMER_DEEPDIVE_DETAILS.replace(
          '{customerId}',
          customerId
        )
          .replace('{id}', groupId)
          .replace('{breakEvenGoalId}', breakEvenGoalId)}`
      )
      .pipe(catchError(this.handleError));
  }

  addOrEditDeepdiveNotes(
    customerId: any,
    breakEvenGoalId: any,
    type: any,
    reqBody?: any
  ) {
    return this.http
      .put(
        `${apiUrl.ADD_OR_DEEPDIVE_EDIT_NOTES.replace(
          '{customerId}',
          customerId
        ).replace('{breakEvenGoalId}', breakEvenGoalId)}`,
        reqBody
      )
      .pipe(catchError(this.handleError));
  }

  deleteDeepdiveNotes(
    customerId: any,
    breakEvenGoalId: any,
    type: any,
    reqBody?: any
  ) {
    const url = `${apiUrl.DELETE_DEEPDIVE_NOTES.replace(
      '{customerId}',
      customerId
    ).replace('{breakEvenGoalId}', breakEvenGoalId)}`;
    return this.http
      .delete(url, { body: reqBody })
      .pipe(catchError(this.handleError));
  }

  getDeepdiveNotesForConsultant(
    customerId: any,
    breakEvenGoalId: any,
    section: any
  ) {
    return this.http
      .get(
        `${apiUrl.GET_CONSULTANT_SECTION_NOTES.replace(
          '{customerId}',
          customerId
        )
          .replace('{breakEvenGoalId}', breakEvenGoalId)
          .replace('{section}', section)}`
      )
      .pipe(catchError(this.handleError));
  }

  getDeepdiveNotesRecommendations(
    customerId: any,
    breakEvenGoalId: any,
  ) {
    return this.http
      .get(
        `${apiUrl.DEEPDIVE_RECOMENDATIONS.replace(
          '{customerId}',
          customerId
        )
          .replace('{breakEvenGoalId}', breakEvenGoalId)}`
      )
      .pipe(catchError(this.handleError));
  }

  publishDeepdive(customerId: any, breakEvenGoalId: any, eventId: any) {
    const url = `${apiUrl.PUBLISH_DEEPDIVE.replace(
      '{breakEvenGoalId}',
      breakEvenGoalId
    )
      .replace('{customerId}', customerId)
      .replace('{eventId}', eventId)}`;
    return this.http.post(url, {}).pipe(catchError(this.handleError));
  }

  //Fetch published deepdive dashboard details
  getPublishedDeepdiveDetailsNotes(customerId: any, breakEvenGoalId: any) {
    return this.http
      .get(
        `${apiUrl.DEEPDIVE_NOTES.replace('{customerId}', customerId)
          .replace('{breakEvenGoalId}', breakEvenGoalId)}`
      )
      .pipe(catchError(this.handleError));
  }

  getDeepdiveCharts(customerId: any, breakEvenGoalId: any) {
    return this.http
      .get(
        `${apiUrl.GET_CHARTS.replace('{customerId}', customerId).replace(
          '{breakEvenGoalId}',
          breakEvenGoalId
        )}`
      )
      .pipe(catchError(this.handleError));
  }

  saveDeepdiveAnswers(
    customerId: any,
    breakEvenGoalId: any,
    groupId: any,
    reqBody?: any
  ) {
    return this.http
      .put(
        `${apiUrl.CHANGE_DEEPDIVE_ANSWERS.replace('{customerId}', customerId)
          .replace('{breakEvenGoalId}', breakEvenGoalId)
          .replace('{groupId}', groupId)}`,
        reqBody
      )
      .pipe(catchError(this.handleError));
  }

  removeCustomer(customerId: any) {
    return this.http
      .delete(`${apiUrl.DELETE_CUSTOMER.replace('{customerId}', customerId)}`)
      .pipe(catchError(this.handleError));
  }

  getDeepdiveTabs(customerId: any, breakEvenGoalId: any) {
    return this.http
      .get(
        `${apiUrl.GET_CONSULTANT_DEEPDIVE_GROUPS.replace(
          '{customerId}',
          customerId
        ).replace('{breakEvenGoalId}', breakEvenGoalId)}`
      )
      .pipe(catchError(this.handleError));
  }
}
