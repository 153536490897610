import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';
import { ConsultantService } from 'src/app/shared/services/consultant.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import {
  ToastService,
  TOAST_STATE,
} from 'src/app/shared/services/toast.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { DeepdiveQuestionsComponent } from '../deepdive-questions/deepdive-questions.component';

@Component({
  selector: 'app-deepdive-assessment-questions',
  templateUrl: './deepdive-assessment-questions.component.html',
  styleUrls: ['./deepdive-assessment-questions.component.scss'],
})
export class DeepdiveAssessmentQuestionsComponent implements OnInit {
  showLoader: boolean = false;
  showButtonLoader: boolean = false;
  @Input() deepDiveGroupQuestions: any;
  @Input() deepdiveTab: any;
  @Input() customerID: any;
  @Input() selectedBreakEvenGoal: any;
  @Input() deepdiveCharts: any;
  @Input() deepdiveTabs: any;
  @Input() refYear: any;
  chartTitle: any = [];

  @Output() onchangingTab = new EventEmitter<any>();
  @Output() onChangingAnswers = new EventEmitter<any>();
  @ViewChild(DeepdiveQuestionsComponent) questions!: DeepdiveQuestionsComponent;
  private consultantService = inject(ConsultantService);
  public errorService = inject(ErrorService);
  public utilService = inject(UtilService);
  private toastService = inject(ToastService);

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.deepdiveTab = this.deepdiveTab || this.deepdiveTabs?.[0];
  }

  onTotalCalculated(total: any): void {
    this.chartTitle.push(total);
    this.cdr.detectChanges();
  }

  onSelectingTab(tab: any) {
    this.deepdiveTab = tab;
    this.getDeepdiveQuestions(tab?.ID);
  }

  onDateSelect(date: any) {
    return !date ? '' : this.utilService.formattingDate(date, '-');
  }

  getTab(direction: string) { }

  onGroupAnswersSubmitted(data: any) { }

  onModifyingAnswers(answersArray?: any) {
    let error_message = '';
    this.showButtonLoader = true;
    const answeredArray = answersArray.reduce(
      (answeredArray: any, eachquestion: any) => {
        // if (!eachquestion?.DISABLED) {
        answeredArray.push({
          QUESTION_ID: eachquestion.ID,
          ANSWERS: eachquestion?.answerlabel
            ? [
              ...eachquestion?.answerlabel?.map(
                (labelValue: any) => labelValue ?? ''
              ),
            ]
            : [
              {
                ANSWER:
                  eachquestion?.TYPE === 'DATE'
                    ? this.onDateSelect(eachquestion?.ANSWERS)
                    : eachquestion?.ANSWERS ?? '',
              },
            ],
        });
        // }
        return answeredArray;
      },
      []
    );

    this.consultantService
      .saveDeepdiveAnswers(
        this.customerID,
        this.selectedBreakEvenGoal?.ID,
        this.deepdiveTab?.ID,
        answeredArray
      )
      .subscribe({
        next: (response: any) => {
          this.toastService.showToast(
            TOAST_STATE.success,
            'Antwoorden zijn opgeslagen'
          );
          this.onChangingAnswers.emit(this.deepdiveTab);
        },
        error: (error) => {
          // handle error
          this.showButtonLoader = false;
          error_message =
            error?.error?.message ??
            'Er ging iets mis. Vernieuw de pagina of neem contact op met het website ondersteuningsteam.';
          this.toastService.showToast(TOAST_STATE.danger, error_message);
          this.utilService.moveToTopOfTerScreen();
        },
        complete: () => {
          //Request completed
          this.showButtonLoader = false;
          this.utilService.moveToTopOfTerScreen();
        },
      });
  }

  /**
   * Fetching deepdive questions per each breakeven goal
   * @param groupId - number | string
   */
  getDeepdiveQuestions(groupId: any) {
    this.errorService.hide();
    // this.loaderService.show();
    this.showLoader = true;
    this.consultantService
      .getConsultantCustomerDeppdiveDetails(
        this.customerID,
        groupId,
        this.selectedBreakEvenGoal?.ID
      )
      .subscribe({
        next: (response: any) => {
          this.deepDiveGroupQuestions = response;
        },
        error: (error) => {
          // handle error
          // this.loaderService.hide();
          this.showLoader = false;
          this.errorService.show();
        },
        complete: () => {
          // Request completed
          this.showLoader = false;
          // this.loaderService.hide();
        },
      });
  }

  onChangeDeepdiveAnswers() {
    let array = this.questions.getQuestionsFormArray();
    if (!array) {
      return;
    }
    if (this.questions?.questionsForm?.status === 'VALID') {
      this.onModifyingAnswers(array);
    }
  }
}
