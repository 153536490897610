<div class="submitAnswerConfirmationModal">
  <div class="modal-header title mb-4 pb-2 p-0">{{ data?.title }}</div>
  <div class="modal-body p-0 mb-5 pb-2">
    {{ data?.description }}
  </div>
  <div
    class="modal-footer p-0 d-flex justify-content-lg-between justify-content-center"
  >
    <button
      class="button-secondary-white"
      (click)="activeModal.dismiss('Cross click')"
      [disabled]="showButtonLoader"
    >
      Cancel
    </button>
    <button class="button-primary-orange" (click)="clickModal()">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
        *ngIf="showButtonLoader"
      ></span>
      <span *ngIf="!showButtonLoader"> {{ data?.buttonLabel }}</span>
    </button>
  </div>
</div>
