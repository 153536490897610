import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BE_GOALS } from 'src/app/shared/utilities/constants';
import { cleanObject, transformArrayToObject, BE_GOALS_PAIR, BE_GOALS_LIST } from './utils';
import {
  ToastService,
  TOAST_STATE,
} from 'src/app/shared/services/toast.service';
import { cloneDeep } from 'lodash';
@Component({
  selector: 'be-goals-list',
  templateUrl: './be-goals-list.component.html',
  styleUrls: ['./be-goals-list.component.scss'],
})
export class BEGoalsList implements OnInit {
  @Output() payload = new EventEmitter<string>();
  @Input() selectedBEGoalsList!: any;
  updatedBEGoalsList: any = [];
  BEGoalsList: any = [];
  applyBoxShadow: boolean = false;

  constructor(private router: Router, private toastService: ToastService) {}

  ngOnInit(): void {
    this.BEGoalsList = BE_GOALS_LIST();

    if (this.selectedBEGoalsList) {
      const transformedObject = transformArrayToObject(this.BEGoalsList);
      this.selectedBEGoalsList.forEach((goal: any) => {
        goal.ICON = transformedObject[goal.BREAK_EVEN_GOAL_ID]?.ICON;
        goal.TITLE = transformedObject[goal.BREAK_EVEN_GOAL_ID]?.TITLE;
        goal.ID = goal.BREAK_EVEN_GOAL_ID;
        goal.checked = true;
      });
      this.updatedBEGoalsList = this.selectedBEGoalsList;

      const objectArry = transformArrayToObject(this.updatedBEGoalsList);

      this.BEGoalsList.forEach((goal: any) => {
        if (objectArry[goal.BREAK_EVEN_GOAL_ID]) {
          goal.checked = true;
          goal.DEEPDIVE_STATE = objectArry[goal.BREAK_EVEN_GOAL_ID].DEEPDIVE_STATE;
        } else {
          goal.checked = false;
        }
      });
    }

    if (this.router.url.indexOf('selected-client') !== -1) {
      this.applyBoxShadow = true;
    }
  }

  onToggleItem(data: any): void {
    this.toggleOneAndSixSelected(data);
    const selectionPayload = {
      ...data,
      BREAK_EVEN_GOAL_ID: data.ID,
      DEEPDIVE_STATE: data.DEEPDIVE_STATE,
      checked: false,
    };

    const id = data.ID;
    const existingIndex = this.updatedBEGoalsList?.findIndex(
      (obj: any) => obj.BREAK_EVEN_GOAL_ID == id
    );

    if (existingIndex == -1) {
      selectionPayload.checked = true;
      this.updatedBEGoalsList.push(selectionPayload);
      if (id == 1 || id == 6) {
        const otherId = id == 1 ? 6 : 1;
        const otherIndex = this.updatedBEGoalsList.findIndex(
          (obj: any) => obj.BREAK_EVEN_GOAL_ID == otherId
        );
        if (otherIndex == -1) {
          this.updatedBEGoalsList.push({
            ...BE_GOALS_PAIR[otherId],
            BREAK_EVEN_GOAL_ID: otherId,
            DEEPDIVE_STATE: 'DEEPDIVE_NOT_STARTED',
            checked: true,
          });
        }
      }
    } else if (existingIndex > -1) {
      this.updatedBEGoalsList.splice(existingIndex, 1);
      if (id == 1 || id == 6) {
        const otherId = id == 1 ? 6 : 1;
        const otherIndex = this.updatedBEGoalsList.findIndex(
          (obj: any) => obj.BREAK_EVEN_GOAL_ID == otherId
        );
        if (otherIndex > -1) {
          this.updatedBEGoalsList.splice(otherIndex, 1);
        }
      }
    }

    const objectArry = transformArrayToObject(this.updatedBEGoalsList);

    this.BEGoalsList.forEach((goal: any) => {
      if (objectArry[goal.BREAK_EVEN_GOAL_ID]) {
        goal.checked = true;
      } else {
        goal.checked = false;
      }
    });

    const cleanedData = this.updatedBEGoalsList.map((goal:any)=> cleanObject(goal));

    this.payload.emit(cleanedData);
  }

  toggleOneAndSixSelected(data: any): void {
    const itemOne = this.BEGoalsList.find((item: any) => item.ID == 1);
    const itemSix = this.BEGoalsList.find((item: any) => item.ID == 6);

    if (data.ID == 6 || data.ID == 1) {
      if (data.checked) {
        itemOne.checked = true;
        itemSix.checked = true;
      } else {
        itemOne.checked = false;
        itemSix.checked = false;
      }
    }
  }
}
