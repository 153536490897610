'use strict';

export const httpUrls = {
  //Client api's
  CUSTOMER: 'customer',
  CUSTOMER_EVENT: 'customer/event/{eventId}',
  QUESTIONS_GROUP: 'group/{groupId}',
  SUBMIT_ANSWERS: 'riskScan/answers',
  GET_RISKSCAN_RESULTS: 'riskScan',
  GET_RISKSCAN_DETAILED_RESULTS: 'riskScan/details/{breakEvenGoalId}',
  DEEPDIVE_OVERVIEW: 'deepdive/{breakEvenGoalId}',
  DEEPDIVE_REFERENCE_YEAR: 'deepdive/{breakEvenGoalId}/year',
  DEEPDIVE_GROUP_QUESTIONS: 'deepdive/{breakEvenGoalId}/group/{id}',
  SAVE_GROUP_DEEPDIVE_ANSWERS:
    'deepdive/{breakEvenGoalId}/answers/group/{groupId}',
  SUBMITDEEPDIVE: 'deepdive/{breakEvenGoalId}/event/{eventId}?year={year}',
  GET_PUBLISHED_DEEPDIVE_DASHBOARD_NOTES: 'deepdive/notes/{breakEvenGoalId}',
  GET_PUBLISHED_DEEPDIVE_DASHBOARD_CHARTS: 'deepdive/details/{breakEvenGoalId}',
  GET_ALL_DEEPDIVES: 'deepdives',
  GET_CUSTOMER_DEEPDIVE_GROUPS: 'deepdive/{breakEvenGoalId}/groups',

  //consultant api's
  CUSTOMERS: 'customers',
  CONSULTANTS: 'consultants',
  SECTOR: 'sector',
  GET_CUSTOMER_DETAILS: 'customer/{customerId}',
  DELETE_CUSTOMER: 'customer/{customerId}',
  GET_RISK_SCAN_BE_GOALS: 'riskScan/{customerId}',
  GET_CUSTOMER_BE_GOAL_DETAILS:
    'riskScan/{customerId}/details/{breakEvenGoalId}',
  POST_CUSTOMER_STATUS: 'customer/{customerId}/event/{eventId}',
  RISK_SCAN_NOTES: 'riskScan/{customerId}/notes/{breakEvenGoalId}',
  CHANGE_RISKSCAN_ANSWERS: 'riskScan/answers/{customerId}',
  MODIFY_GROUP_DEEPDIVE_ANSWERS:
    'deepdive/{breakEvenGoalId}/{customerId}/answers/{year}',
  CONSULTANT_CUSTOMER_DEEPDIVE_OVERVIEW: 'deepdive/customer/{customerId}',
  CONSULTANT_CUSTOMER_DEEPDIVE_DETAILS:
    'deepdive/customer/{customerId}/group/{id}/{breakEvenGoalId}',
  DEEPDIVE_NOTES: 'deepdive/customer/{customerId}/notes/{breakEvenGoalId}',
  ADD_OR_DEEPDIVE_EDIT_NOTES: 'deepdive/customer/{customerId}/notes/{breakEvenGoalId}',
  DELETE_DEEPDIVE_NOTES:
    'deepdive/customer/{customerId}/notes/{breakEvenGoalId}',
  DEEPDIVE_RECOMENDATIONS: 'deepdive/customer/{customerId}/notes/{breakEvenGoalId}/similar',
  GET_CONSULTANT_SECTION_NOTES:
    'deepdive/customer/{customerId}/notes/{breakEvenGoalId}/section/{section}',
  PUBLISH_DEEPDIVE: 'deepdive/{breakEvenGoalId}/{customerId}/event/{eventId}',
  GET_CHARTS: 'deepdive/customer/{customerId}/details/{breakEvenGoalId}',
  CHANGE_DEEPDIVE_ANSWERS:
    'deepdive/customer/{customerId}/answers/{breakEvenGoalId}/group/{groupId}',
  GET_CONSULTANT_DEEPDIVE_GROUPS:
    'deepdive/customer/{customerId}/groups/{breakEvenGoalId}',
};
