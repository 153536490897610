<form [formGroup]="form">
  <div class="client-info-block" *ngIf="!editClient">
    <p class="sub-title">Contact informatie</p>
    <div class="form-group mb-4 pb-2 input-box">
      <label class="label">Email</label>
      <input
        type="email"
        formControlName="email"
        class="form-control customInput"
        id="email"
        [ngClass]="{
          errorInputBorder:
            (form.controls['email'].hasError('required') ||
              form.controls['email'].hasError('pattern')) &&
            form.controls['email'].touched
        }"
        placeholder="Email"
        (keydown.space)="utilService.restrictLeadingSpace($event)"
        autocomplete="off"
      />
      <div
        class="d-flex pt-2"
        id="email-error-message"
        [ngClass]="
          (form.controls['email'].hasError('required') ||
            form.controls['email'].hasError('pattern')) &&
          form.controls['email'].touched
            ? 'visible'
            : 'invisible'
        "
      >
        <img
          src="../../assets/images/exclamation-circle.svg"
          alt="Error"
          class="align-self-start"
        />
        <span
          class="error px-1"
          *ngIf="form.controls['email'].hasError('required')"
          >Email is vereist</span
        >

        <span
          class="error px-1"
          *ngIf="form.controls['email'].hasError('pattern')"
          >Gebruik een geldig email adres</span
        >
      </div>
    </div>
  </div>
  <div
    class="client-info-block"
    [ngClass]="{ 'cursor-not-allowed': editClient && selectedMenu !== 'edit' }"
  >
    <p class="sub-title d-flex justify-content-between align-items-center">
      <span>Bedrijfsinformatie</span>
      <span
        ngbDropdown
        *ngIf="editClient"
        class="cursor"
        placement="bottom-end"
      >
        <img
          src="../../../assets/images/context-menu.svg"
          alt="Context-menu"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          autocomplete="off"
          ngbDropdownToggle
          id="client_menu"
        />
        <div
          class="client-contact-dropdown"
          ngbDropdownMenu
          aria-labelledby="client_menu"
        >
          <ul>
            <li
              class="select-options"
              *ngFor="let option of menuOptions"
              ngbDropdownItem
              role="option"
              (click)="selectMenuOption(option)"
              attr.value="{{ option?.dataKey }}"
              [disabled]="option?.disabled"
              [ngClass]="{ active: option?.key === selectedMenu }"
            >
              <span>{{ option?.label }}</span>
            </li>
          </ul>
        </div>
      </span>
    </p>

    <div class="d-flex flex-column flex-md-row ">
      <div class="form-group me-4 pe-3 input-box">
        <label class="label">Bedrijfsnaam</label>
        <input
          type="text"
          formControlName="companyName"
          class="form-control customInput"
          [id]="form.controls['companyName'].value"
          [ngClass]="{
            errorInputBorder:
              form.controls['companyName'].hasError('required') &&
              form.controls['companyName'].touched,
            'pointer-events-none': editClient && selectedMenu !== 'edit'
          }"
          placeholder="Bedrijfsnaam"
          (keydown.space)="utilService.restrictLeadingSpace($event)"
          minlength="1"
          maxlength="30"
          autocomplete="off"
          [readonly]="editClient && selectedMenu !== 'edit'"
        />
        <div
          class="d-flex pt-2"
          id="companyName-error-message"
          [ngClass]="
            form.controls['companyName'].hasError('required') &&
            form.controls['companyName'].touched
              ? 'visible'
              : 'invisible'
          "
        >
          <img
            src="../../assets/images/exclamation-circle.svg"
            alt="Error"
            class="align-self-start"
          />
          <span
            class="error px-1"
            *ngIf="form.controls['companyName'].hasError('required')"
            >Bedrijfsnaam is vereist</span
          >
        </div>
      </div>
      <div class="form-group input-box">
        <label class="label">KVK nummer</label>
        <input
          type="text"
          formControlName="kvk"
          class="form-control customInput"
          id="kvkNumber"
          [ngClass]="{
            errorInputBorder:
              form.controls['kvk'].hasError('required') &&
              form.controls['kvk'].touched,
            'pointer-events-none': editClient && selectedMenu !== 'edit'
          }"
          placeholder="0000 0000"
          (keydown.space)="utilService.restrictLeadingSpace($event)"
          autocomplete="off"
          maxlength="9"
          onlynumber
          kvk-format
          [readonly]="editClient && selectedMenu !== 'edit'"
          (paste)="(false)"
        />
        <div
          class="d-flex pt-2"
          id="kvk-error-message"
          [ngClass]="
            (form.controls['kvk'].hasError('required') ||
              form.controls['kvk'].hasError('pattern')) &&
            form.controls['kvk'].touched
              ? 'visible'
              : 'invisible'
          "
        >
          <img
            src="../../assets/images/exclamation-circle.svg"
            alt="Error"
            class="align-self-start"
          />
          <span
            class="error px-1"
            *ngIf="form.controls['kvk'].hasError('required')"
            >KVK is vereist</span
          >
          <span
            class="error px-1"
            *ngIf="form.controls['kvk'].hasError('pattern')"
            >KVK nummer moet altijd 8 cijfers bevatten</span
          >
        </div>
      </div>
    </div>
    <div class="d-flex flex-column flex-md-row">
      <div class="form-group me-4 pe-3">
        <label class="label">Sector</label>
        <app-select-searchable
          [formGroup]="form"
          [selectOptions]="sectorData"
          id="sector"
          controlName="sector"
          (selectedOption)="onOptionSelected($event)"
          [readonly]="editClient && selectedMenu !== 'edit'"
        ></app-select-searchable>
      </div>
      <div class="form-group">
        <label class="label">Sub-sector</label>
        <app-select-searchable
          [formGroup]="form"
          [selectOptions]="subSectorList"
          id="subSector"
          controlName="subSector"
          (selectedOption)="onOptionSelected($event)"
          [disabled]="!form.controls['sector'].value"
          [readonly]="editClient && selectedMenu !== 'edit'"
        ></app-select-searchable>
      </div>
    </div>
    <div class="d-flex flex-column flex-md-row">
      <div class="form-group me-4 pe-3">
        <label class="label">Bedrijfsactiviteit</label>
        <app-select-searchable
          [formGroup]="form"
          [selectOptions]="businessActivityList"
          id="businessActivity"
          controlName="businessActivity"
          (selectedOption)="onOptionSelected($event)"
          [disabled]="!form.controls['subSector'].value"
          [readonly]="editClient && selectedMenu !== 'edit'"
        ></app-select-searchable>
      </div>
      <div class="form-group">
        <label class="label">Kring</label>
        <app-select-searchable
          [formGroup]="form"
          [selectOptions]="clientRegionsList"
          id="clientRegion"
          controlName="clientRegion"
          (selectedOption)="onOptionSelected($event)"
          [disabled]="!form.controls['businessActivity'].value"
          [readonly]="editClient && selectedMenu !== 'edit'"
        ></app-select-searchable>
      </div>
    </div>
    <div class="d-flex flex-column flex-md-row" *ngIf="editClient">
      <div class="form-group me-4 pe-3">
        <label class="label">Duurzaamheidsexpert</label>
        <app-select-searchable
          [formGroup]="form"
          [selectOptions]="consultantList"
          id="assignedConsultant"
          controlName="assignedConsultant"
          (selectedOption)="onOptionSelected($event)"
          [disabled]="!form.controls['clientRegion'].value"
          [readonly]="editClient && selectedMenu !== 'edit'"
        ></app-select-searchable>
      </div>
    </div>

  </div>
</form>
