import { Component, inject, Input, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { cloneDeep, find, set } from 'lodash';
import { ConsultantService } from 'src/app/shared/services/consultant.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import {
  ToastService,
  TOAST_STATE,
} from 'src/app/shared/services/toast.service';
import { UtilService } from 'src/app/shared/services/util.service';
import {
  BE_GOALS,
  COMMENT_MENU_OPTIONS,
} from 'src/app/shared/utilities/constants';
import { ModalComponent } from '../modals/modal/modal.component';
import { forbiddenWordsChecker } from '../../shared/utilities/forbidden-words-checker';
@Component({
  selector: 'app-deepdive-notes',
  templateUrl: './deepdive-notes.component.html',
  styleUrls: ['./deepdive-notes.component.scss'],
})
export class DeepdiveNotesComponent implements OnInit {
  modal: any;
  notesForm!: FormGroup;
  formBuilder = inject(FormBuilder);
  menuSelected: any = {};
  menuOptions: any = cloneDeep(COMMENT_MENU_OPTIONS);
  @Input() type: any;
  @Input() breakEvenGoal: any;
  @Input() notesDetails: any;
  @Input() customerID: any;
  @Input() selectedType: any;
  unit: any;
  public errorService = inject(ErrorService);
  private modalService = inject(ModalService);
  private toastService = inject(ToastService);
  public utilService = inject(UtilService);
  private consultantService = inject(ConsultantService);
  maxTextEditors: any = [1, 2, 3, 4];

  constructor() {}

  ngOnInit(): void {
    this.initForm(this.notesDetails ?? '');
    this.unit = BE_GOALS.find(
      (goal) => goal.TITLE === this.breakEvenGoal?.TITLE
    )?.UNIT;
  }

  ngOnChanges() {
    this.selectedType = this.selectedType;
    this.notesDetails = this.notesDetails;
    this.initForm(this.notesDetails ?? '');
  }

  populateNoteSections(notes: any) {
    return notes?.map((note: any, index: any) =>
      this.addSectionEditor(note, index + 1)
    );
  }

  hasForbiddenWord(notes: any){
    return forbiddenWordsChecker(notes)?.isError;
  }

  initForm(notes?: any) {
    this.notesForm = this.formBuilder.group({
      notesSections: this.formBuilder.array(
        notes?.length
          ? this.populateNoteSections(notes)
          : [this.addSectionEditor('', 1)]
      ),
    });
  }

  addSectionEditor(note?: any, index?: any) {
    return this.formBuilder.group({
      ID: this.formBuilder.control(note?.ID ?? index),
      NUMBER: new FormControl(
        note?.NUMBER ?? '',
        this.unit === '%' ? [Validators.min(0), Validators.max(100)] : []
      ),
      TEXT: this.formBuilder.control(note?.TEXT ?? '', [Validators.required]),
      ADDED: this.formBuilder.control(note?.TEXT ? true : false),
      LOADER: this.formBuilder.control(false),
      SAVED: this.formBuilder.control(false),
      TITLE: this.formBuilder.control(note?.TITLE ?? ''),
      //Adding this formcontrol only for the Actions tab
      NOTETITLE: new FormControl(
        note?.NOTETITLE ?? '',
        this.type === 'ACTIONS' ? Validators.required : []
      ),
    });
  }

  addRegulationSection() {
    const missedID = this.maxTextEditors.filter((total: any) =>
      this.noteSections?.value.every((note: any) => parseInt(note.ID) !== total)
    );

    this.noteSections.push(this.addSectionEditor('', missedID[0]));
  }

  get noteSections() {
    return this.notesForm.controls['notesSections'] as FormArray;
  }

  saveSection(section: any, index: number) {
    this.noteSections?.at(index)?.get('ADDED')?.setValue(true);
  }

  addOrEditNotesSection(section: any, index: number) {
    let error_message = '';
    let message = '';
    this.noteSections?.at(index)?.get('LOADER')?.setValue(true);
    const notesObject = {
      ID: Number(this.noteSections?.at(index)?.get('ID')?.value),
      TEXT: this.noteSections?.at(index)?.get('TEXT')?.value,
    };

    //Add goal percentage value
    if (this.type === 'GOALS') {
      set(
        notesObject,
        'NUMBER',
        this.noteSections?.at(index)?.get('NUMBER')?.value
      );
    }

    //Adding title for action notes
    if (this.type === 'ACTIONS') {
      set(
        notesObject,
        'NOTETITLE',
        this.noteSections?.at(index)?.get('NOTETITLE')?.value
      );
    }

    const postObj = {
      [this.type]: [notesObject]
    };

    this.consultantService
      .addOrEditDeepdiveNotes(
        this.customerID,
        this.breakEvenGoal.ID,
        this.type,
        postObj
      )
      .subscribe({
        next: (response: any) => {
          this.utilService.moveToTopOfTerScreen();

          message =
            this.menuSelected?.dataKey === 'edit'
              ? 'Toelichting is opgeslagen'
              : 'Toelichting is toegevoegd';
          this.toastService.showToast(TOAST_STATE.success, message);
          this.utilService.setControlValue(
            this.noteSections,
            'ADDED',
            true,
            index
          );
          this.menuSelected = {};
        },
        error: (error) => {
          // handle error
          this.noteSections?.at(index)?.get('LOADER')?.setValue(false);
          error_message =
            error?.error?.message ??
            'Er ging iets mis. Vernieuw de pagina of neem contact op met het website ondersteuningsteam.';
          this.utilService.moveToTopOfTerScreen();
          this.toastService.showToast(TOAST_STATE.danger, error_message);
        },
        complete: () => {
          //Request completed
          this.noteSections?.at(index)?.get('LOADER')?.setValue(false);
        },
      });
  }

  onMenuOptionSelected(option: any, section: any, index: number) {
    this.menuSelected = option;
    if (option?.dataKey === 'edit') {
      this.noteSections?.at(index)?.get('ADDED')?.setValue(false);
    } else {
      let title = 'Toelichting verwijderen';
      let description =
        'Weet u zeker dat u de toelichting wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden.';
      let buttonLabel = 'Verwijderen';
      this.openModal(title, description, buttonLabel, 'deleteText', index);
      return;
    }
  }

  /**
   * It opens the confirmation modal
   */
  openModal(
    title?: string,
    description?: string,
    buttonLabel?: string,
    modalType?: string,
    index?: any
  ) {
    this.modal = this.modalService.open(ModalComponent, {
      backdrop: 'static',
      windowClass: 'submitAnswersModal',
      centered: true,
    });
    this.modal.componentInstance.data = {
      title,
      description,
      buttonLabel,
      showCancelButton: true,
    };
    this.modal.componentInstance.actionButtonClicked.subscribe(
      (actionType: string) => {
        if (actionType === 'Verwijderen') {
          this.deleteTextInfo(index);
          return;
        }
      }
    );
  }

  deleteTextInfo(index?: any) {
    let message = '';
    let error_message = '';
    this.utilService.setControlValue(this.noteSections, 'LOADER', true, index);

    const postObj = {
      [this.type]: [
        {
          ID: this.noteSections?.at(index)?.get('ID')?.value,
        },
      ],
    };
    this.consultantService
      .deleteDeepdiveNotes(
        this.customerID,
        this.breakEvenGoal.ID,
        this.type,
        postObj
      )
      .subscribe({
        next: (response: any) => {
          if (this.type === 'REGULATIONS' || this.type === 'ACTIONS') {
            if (this.noteSections?.value?.length > 1) {
              this.noteSections?.removeAt(index);
            } else {
              this.utilService.resetControlValue(
                this.noteSections,
                'TEXT',
                index
              );
              this.utilService.setControlValue(
                this.noteSections,
                'ADDED',
                false,
                index
              );
            }
          } else {
            this.utilService.resetControlValue(
              this.noteSections,
              'TEXT',
              index
            );
            this.utilService.setControlValue(
              this.noteSections,
              'ADDED',
              false,
              index
            );
          }
          this.utilService.moveToTopOfTerScreen();

          this.toastService.showToast(
            TOAST_STATE.success,
            'Toelichting verwijdert'
          );
          this.modal.dismiss();
        },
        error: (error) => {
          // handle error
          this.utilService.setControlValue(
            this.noteSections,
            'LOADER',
            false,
            index
          );
          this.modal.dismiss();
          // handle error
          error_message =
            error?.error?.message ??
            'Er ging iets mis. Vernieuw de pagina of neem contact op met het website ondersteuningsteam.';
          this.utilService.moveToTopOfTerScreen();
          this.toastService.showToast(TOAST_STATE.danger, error_message);
        },
        complete: () => {
          //Request completed
          this.utilService.setControlValue(
            this.noteSections,
            'LOADER',
            false,
            index
          );
        },
      });
  }

  getNoteTitle(index: number) {
    return find(this.selectedType?.subTitles, ['sectionId', index]);
  }

  clearEditor(section: any, index: number) {
    if (this.menuSelected?.dataKey === 'edit') {
      this.noteSections?.at(index)?.get('ADDED')?.setValue(true);
      this.menuSelected = {};
    } else {
      this.noteSections?.at(index)?.get('TEXT')?.setValue('');
    }
  }

  ngOnDestroy() {
    this.toastService.dismissToast();
  }
}
