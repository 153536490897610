<div class="notes-layout">
  <form [formGroup]="notesForm">
    <div formArrayName="notesSections">
      <ng-container
        *ngFor="
          let eachNote of noteSections.controls;
          let index = index;
          last as isLast
        "
      >
        <div [formGroupName]="index">
          <div class="d-flex justify-content-between">
            <div>
              <ng-container *ngIf="breakEvenGoal.ID == '1'">
                <p class="deepdive-notes-title" *ngIf="type === 'PERFORMANCE'">
                  {{ index === 0 ? "Hernieuwbare energie" : "Energieverbruik" }}
                </p>
              </ng-container>
              <ng-container *ngIf="breakEvenGoal.ID == '2'">
                <p class="deepdive-notes-title" *ngIf="type === 'PERFORMANCE' && index == 0">
                  Waterinvoer
                </p>
                <p class="deepdive-notes-title" *ngIf="type === 'PERFORMANCE' && index == 1">
                  Waterbesparing
                </p>
                <p class="deepdive-notes-title" *ngIf="type === 'PERFORMANCE' && index == 2">
                  Waterafvoer
                </p>
              </ng-container>
              <ng-container *ngIf="breakEvenGoal.ID == '6'">
                <p class="deepdive-notes-title" *ngIf="type === 'PERFORMANCE'">
                  Broeikasemissies
                </p>
              </ng-container>
              <ng-container *ngIf="breakEvenGoal.ID == '7'">
                <p class="deepdive-notes-title" *ngIf="type === 'PERFORMANCE'">
                  Afval
                </p>
              </ng-container>

              <div class="goal-text" *ngIf="type === 'GOALS' && breakEvenGoal.ID != '2'">
                <p class="deepdive-notes-title" *ngIf="selectedType?.subTitles">
                  Voorstel doel
                </p>
                <ng-container *ngIf="breakEvenGoal.ID == '1'">
                  <span class="me-2 pe-1">
                    Voorgestelde doel voor de klant is om
                  </span>
                </ng-container>
                <ng-container *ngIf="breakEvenGoal.ID == '6'">
                  <span class="me-2 pe-1">
                    Voorgestelde doel voor de klant is om zijn broeikasemissies te reduceren tot
                  </span>
                </ng-container>
                <ng-container *ngIf="breakEvenGoal.ID == '7'">
                  <span class="me-2 pe-1">
                    Voorgestelde doel voor de klant is om
                  </span>
                </ng-container>
                <ng-container>
                  <input
                    *ngIf="!eachNote?.get('ADDED')?.value"
                    formControlName="NUMBER"
                    onlynumber
                    [ngClass]="{
                      errorInputBorder:
                        (eachNote.get('NUMBER')?.hasError('min') ||
                          eachNote.get('NUMBER')?.hasError('max')) &&
                        eachNote.get('NUMBER')?.touched
                    }"
                  />
                  <span *ngIf="eachNote?.get('ADDED')?.value">{{
                    eachNote.get("NUMBER")?.value
                  }}</span>
                  {{ unit ?? "" }}
                </ng-container>
                <ng-container *ngIf="breakEvenGoal.ID == '1'">
                  <span class="ms-2 ps-1">
                    hernieuwbare energie te gebruiken in 2030.
                  </span>
                </ng-container>
                <ng-container *ngIf="breakEvenGoal.ID == '6'">
                  <span class="ms-2 ps-1">
                    in 2030.
                  </span>
                </ng-container>
                <ng-container *ngIf="breakEvenGoal.ID == '7'">
                  <span class="ms-2 ps-1">
                    afval als doel voor 2030 te stellen.
                  </span>
                </ng-container>
                <ng-container *ngIf="unit === '%'">
                  <div
                    class="d-flex pt-2"
                    id="error_message_goal"
                    [ngClass]="
                      (eachNote.get('NUMBER')?.hasError('min') ||
                        eachNote.get('NUMBER')?.hasError('max')) &&
                      eachNote.get('NUMBER')?.touched
                        ? 'visible'
                        : 'invisible'
                    "
                  >
                    <img
                      src="../../../assets/images/exclamation-circle.svg"
                      alt="Error"
                      class="align-self-start"
                    />
                    <span
                      class="error px-1"
                      *ngIf="
                        eachNote?.get('NUMBER')?.hasError('min') ||
                        eachNote?.get('NUMBER')?.hasError('max')
                      "
                      >Maximale toegestane waarde is 100</span
                    >
                  </div>
                </ng-container>
              </div>

              <div class="goal-text" *ngIf="type === 'GOALS' && breakEvenGoal.ID == '2'">
                <p class="deepdive-notes-title" *ngIf="selectedType?.subTitles && index == 0">
                  Titel doel
                </p>
                <p class="deepdive-notes-title" *ngIf="selectedType?.subTitles && index == 1">
                  Omschrijving doel
                </p>
              </div>

              <!-- Action title starts-->
              <div
                class="actions-text-section mb-3 pb-2"
                *ngIf="type === 'ACTIONS'"
              >
                <p class="deepdive-notes-title">Titel actie</p>
                <input
                  formControlName="NOTETITLE"
                  class="actions-title"
                  *ngIf="!eachNote?.get('ADDED')?.value"
                />
                <span *ngIf="eachNote?.get('ADDED')?.value">{{
                  eachNote?.get("NOTETITLE")?.value
                }}</span>
              </div>

              <!-- Action title ends-->

              <!-- Adding static title only for the actions tab section-->
              <p class="deepdive-notes-title" *ngIf="type === 'ACTIONS'">
                Beschrijving actie
              </p>
              <!-- <p class="deepdive-text-info" *ngIf="eachNote?.get('ADDED')?.value"> -->
              <span
                [innerHTML]="eachNote?.get('TEXT')?.value"
                *ngIf="eachNote?.get('ADDED')?.value"
              >
              </span>
            </div>
            <div *ngIf="eachNote?.get('ADDED')?.value">
              <app-context-menu
                [menuOptions]="menuOptions"
                (menuOptionSelected)="
                  onMenuOptionSelected($event, eachNote, index)
                "
              ></app-context-menu>
            </div>
          </div>

          <!-- <app-context-menu
              [menuOptions]="menuOptions"
              (menuOptionSelected)="
                onMenuOptionSelected($event, eachNote, index)
              "
            ></app-context-menu> -->
          <!-- </p> -->

          <app-text-editor
            *ngIf="!eachNote?.get('ADDED')?.value"
            id="{{ type }}_{{ index }}"
            [editorFormGroup]="eachNote"
            controlName="TEXT"
          ></app-text-editor>

          <div
            class="add-buttons-block mt-4 mb-4 pb-3 d-flex justify-content-end"
            *ngIf="!eachNote?.get('ADDED')?.value"
          >
            <button
              class="button-cancel bg-white border-0 me-4"
              (click)="clearEditor(eachNote, index)"
            >
              Cancel
            </button>
            <button
              class="button-primary-white ms-2"
              (click)="addOrEditNotesSection(eachNote, index)"
              [disabled]="
                !eachNote?.get('TEXT')?.value ||
                !eachNote?.valid ||
                !eachNote?.get('NUMBER')?.valid ||
                hasForbiddenWord(eachNote?.get('TEXT')?.value)
              "
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
                *ngIf="eachNote?.get('LOADER')?.value"
              ></span>
              <span *ngIf="!eachNote?.get('LOADER')?.value">{{
                menuSelected?.dataKey === "edit" ? "Opslaan" : "Toevoegen"
              }}</span>
            </button>
          </div>
          <hr class="ruler" *ngIf="!isLast" />
        </div>
      </ng-container>
    </div>
    <div
      class="mt-4 pt-3 d-flex justify-content-end"
      *ngIf="type === 'REGULATIONS' || type === 'ACTIONS'"
    >
      <button
        class="button-primary-white"
        (click)="addRegulationSection()"
        [disabled]="noteSections.value.length >= 4"
      >
        Een tekst sectie toevoegen
      </button>
    </div>
  </form>
</div>
