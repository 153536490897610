<div class="risk-scan-charts-layout">
  <div
    class="deepdive-chart me-4 mb-4"
    *ngFor="let deepdive of deepdiveStates; let i = index"
    [ngClass]="{ cursor: deepdive?.DEEPDIVE_STATE === 'DEEPDIVE_PUBLISHED' }"
    (click)="
      deepdive?.DEEPDIVE_STATE === 'DEEPDIVE_PUBLISHED' &&
        navigateToDeepDiveInitialPage(deepdive)
    "
  >
    <p class="goal-title mb-3">{{ deepdive?.BREAK_EVEN_GOAL_TITLE ?? "" }}</p>
    <ng-container *ngIf="deepdive?.DEEPDIVE_STATE !== 'DEEPDIVE_PUBLISHED'">
      <img src="../../../assets/images/deepdive-not-started.svg" />
      <p class="explanation">Ontdek uw voortgang</p>
      <button
        class="button-primary-white"
        (click)="navigateToDeepDiveInitialPage(deepdive)"
      >
        <span *ngIf="deepdive?.DEEPDIVE_STATE === 'DEEPDIVE_NOT_STARTED'"
          >Start assessment</span
        >
        <span *ngIf="deepdive?.DEEPDIVE_STATE === 'DEEPDIVE_STARTED'"
          >Verder met assessment</span
        >
        <span
          *ngIf="
            deepdive?.DEEPDIVE_STATE === 'DEEPDIVE_SUBMITTED' ||
            deepdive?.DEEPDIVE_STATE === 'DEEPDIVE_PUBLISHED'
          "
          style="font-size: 17px"
          >Resultaten in behandeling</span
        >
      </button>
    </ng-container>
    <ng-container *ngIf="deepdive?.DEEPDIVE_STATE === 'DEEPDIVE_PUBLISHED'">
      <app-chart
        [id]="i"
        [chartDetails]="utilService.getChartData(deepdive?.CHART, deepdive?.BREAK_EVEN_GOAL_TITLE)"
        [width]="600"
      ></app-chart>
    </ng-container>
  </div>
</div>
