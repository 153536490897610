import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  public customerInfo = new BehaviorSubject(null);
  public riskScanDashboardState = new BehaviorSubject(null);
  public consultantOverviewFilters = new BehaviorSubject(null);

  setConsultantOverviewFilters(value: any) {
    this.consultantOverviewFilters.next(value);
  }

  getConsultantOverviewFilters(): Observable<any> {
    return this.consultantOverviewFilters.asObservable();
  }

  setCustomerInfo(value: any) {
    this.customerInfo.next(value);
  }

  getCustomerInfo(): Observable<any> {
    return this.customerInfo.asObservable();
  }

  setRiskScanDashboardState(value: any) {
    this.riskScanDashboardState.next(value);
  }

  getRiskScanDashboardState(): Observable<any> {
    return this.riskScanDashboardState.asObservable();
  }
}
