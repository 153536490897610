<!-- <div class="tabs-header">
    <ul ngbNav #nav="ngbNav" class="nav-pills flex-row">
      <li
        id="{{ link.route }}"
        [ngbNavItem]="link.route"
        *ngFor="let link of assessmentDeepdiveTabs; let i = index"
        class="me-4 pe-2"
      >
        <a
          ngbNavLink
          (click)="onSelectedTab(link)"
          [ngClass]="{ active: link?.route === assessmentDeepdiveTabSelected?.route }"
        >
          <img
            *ngIf="link?.saved"
            src="../../../../assets/images/green-tick.svg"
            alt="Answers saved"
            class="me-2 pe-1"
          />
          <span>{{ link.title }}</span>
        </a>
        <ng-template ngbNavContent>
          <ng-container *ngIf="link.route === 'results'">
            <app-deepdive-assessment-questions
              [deepDiveGroupQuestions]="groupDeepdiveQuestions"
              [deepdiveTab]="deepdiveSelectedTab"
              (onchangingTab)="changingTab($event)"
            ></app-deepdive-assessment-questions>
          </ng-container>
          <ng-container *ngIf="link.route === 'comments'">
            <app-assessment-comments-tab
              [selectedBEGoal]="selectedBreakEvenGoal"
            >
            </app-assessment-comments-tab>
          </ng-container>
        </ng-template>
      </li>
    </ul>
  </div> -->

<div class="tabs-header">
  <ul
    ngbNav
    #nav="ngbNav"
    class="nav-pills flex-row"
    (navChange)="onNavChange($event)"
  >
    <li id="be-goal-tab" [ngbNavItem]="'results'" class="me-4 pe-2">
      <a ngbNavLink>
        <span>Resultaten</span>
      </a>
      <ng-template ngbNavContent>
        <app-deepdive-assessment-questions
          [deepDiveGroupQuestions]="groupDeepdiveQuestions"
          (onDeepdiveTabSelected)="selectingDeepdiveTab($event)"
        ></app-deepdive-assessment-questions
      ></ng-template>
    </li>
    <li id="sdg-tab" [ngbNavItem]="'notes'" class="me-4 pe-2">
      <a ngbNavLink>
        <span>Notities</span>
      </a>
      <ng-template ngbNavContent>
        <app-assessment-comments-tab></app-assessment-comments-tab
      ></ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-4"></div>
</div>
