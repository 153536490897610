<div class="main-tab-content">
  <form>
    <fieldset [formGroup]="questionsForm">
      <div formArrayName="array">
        <div
          *ngFor="let value of deepDiveQuestions; let i = index"
          [ngSwitch]="true"
        >
          <div
            class="form-group mb-2"
            [formGroupName]="i"
            [style.border]="value?.ID > 42 && value?.ID < 68 ? 'none' : ''"
            [style.padding]="value?.ID > 42 && value?.ID < 68 ? '0' : ''"
            [style.display]="
              value?.ID > 55 && value?.ID < 68 && value?.DISABLED ? 'none' : ''
            "
          >
            <div *ngIf="value?.ID == 43">
              <p>
                1. Vink hieronder aan welke afvalstromen u heeft binnen uw
                bedrijf:
              </p>
            </div>
            <div [attr.data-id]="value?.ID">
              <div *ngIf="value?.ID > 42 && value?.ID < 68; else regularLabel">
                <label
                  class="label label-bold"
                  [style.display]="
                    value?.ID > 55 && value?.ID < 68 ? 'none' : ''
                  "
                >
                  <span [innerHTML]="value?.TEXT"></span>
                </label>
              </div>
              <ng-template #regularLabel>
                <markdown >
                  <label class="label">
                    {{ i + 1 }}. <span [innerHTML]="value?.TEXT"></span>
                  </label>
                </markdown>
              </ng-template>
              <fieldset *ngSwitchDefault [disabled]="value?.DISABLED">
                <input
                  class="form-control customInput input-generic"
                  [id]="value?.ID"
                  (keydown.space)="utilService.restrictLeadingSpace($event)"
                  autocomplete="off"
                  placeholder="{{ value?.PLACEHOLDER }}"
                  formControlName="ANSWERS"
                  minlength="{{ value?.MIN_VALUE }}"
                  maxlength="{{ value?.MAX_VALUE }}"
                  [ngClass]="{
                    errorInputBorder:
                      (getArrayControls()
                        [i].get('ANSWERS')
                        ?.hasError('minlength') ||
                        getArrayControls()
                          [i].get('ANSWERS')
                          ?.hasError('maxlength')) &&
                      getArrayControls()[i].get('ANSWERS')?.touched
                  }"
                />
                <div
                  class="d-flex pt-2"
                  id="error_message_{{ value?.ID }}"
                  [ngClass]="
                    (getArrayControls()
                      [i].get('ANSWERS')
                      ?.hasError('minlength') ||
                      getArrayControls()
                        [i].get('ANSWERS')
                        ?.hasError('maxlength')) &&
                    getArrayControls()[i].get('ANSWERS')?.touched
                      ? 'visible'
                      : 'invisible'
                  "
                >
                  <img
                    src="../../../assets/images/exclamation-circle.svg"
                    alt="Error"
                    class="align-self-start"
                  />
                  <span
                    class="error px-1"
                    *ngIf="
                        getArrayControls()?.[i]?.get('ANSWERS')?.hasError('minlength') ||
                        getArrayControls()?.[i]?.get('ANSWERS')?.hasError('maxlength')
                        "
                    >Maximaal aantal tekens voor deze vraag is bereikt</span
                  >
                </div>
              </fieldset>
              <!-- Textarea start -->
              <fieldset
                *ngSwitchCase="value?.TYPE === 'TEXT_AREA'"
                [disabled]="value?.DISABLED"
              >
                <textarea
                  [id]="value?.ID"
                  class="form-control text-area"
                  (keydown.space)="utilService.restrictLeadingSpace($event)"
                  autocomplete="off"
                  placeholder="{{ value?.PLACEHOLDER }}"
                  formControlName="ANSWERS"
                  minlength="{{ value?.MIN_VALUE }}"
                  maxlength="{{ value?.MAX_VALUE }}"
                  [ngClass]="{
                    errorInputBorder:
                      (getArrayControls()
                        [i].get('ANSWERS')
                        ?.hasError('minlength') ||
                        getArrayControls()
                          [i].get('ANSWERS')
                          ?.hasError('maxlength')) &&
                      getArrayControls()[i].get('ANSWERS')?.touched
                  }"
                ></textarea>
                <div
                  class="d-flex pt-2"
                  id="error_message_{{ value?.ID }}"
                  [ngClass]="
                    (getArrayControls()
                      [i].get('ANSWERS')
                      ?.hasError('minlength') ||
                      getArrayControls()
                        [i].get('ANSWERS')
                        ?.hasError('maxlength')) &&
                    getArrayControls()[i].get('ANSWERS')?.touched
                      ? 'visible'
                      : 'invisible'
                  "
                >
                  <img
                    src="../../../assets/images/exclamation-circle.svg"
                    alt="Error"
                    class="align-self-start"
                  />
                  <span
                    class="error px-1"
                    *ngIf="
                      getArrayControls()?.[i]?.get('ANSWERS')?.hasError('minlength') ||
                      getArrayControls()?.[i]?.get('ANSWERS')?.hasError('maxlength')
                      "
                    >Maximaal aantal tekens voor deze vraag is bereikt</span
                  >
                </div>
              </fieldset>
              <!-- Textarea end -->
              <!--  Number start -->
              <fieldset
                *ngSwitchCase="
                  value?.TYPE === 'NUMBER' || value?.TYPE === 'PERCENTAGE'
                "
                [disabled]="value?.DISABLED"
              >
                <div
                  *ngIf="!value?.DISABLED; else regularNumberInput"
                  [class.number-row]="value?.ID > 55 && value?.ID < 68"
                  [style.display]="
                  !(value?.ID > 55 && value?.ID < 68) ? '' : 'block'
                "
         
                >
                  <label
                    class="label"
                    [style.width]=" '40%' "
                    [style.display]="
                      !(value?.ID > 55 && value?.ID < 68) ? 'none' : ''
                    "
                  >
                    <span [innerHTML]="value?.TEXT"></span>
                  </label>
                  <input
                    
                    [class.form-control]="!(value?.ID > 55 && value?.ID < 68)"
                    [class.customInput]="!(value?.ID > 55 && value?.ID < 68)"
                    [class.input-generic]="!(value?.ID > 55 && value?.ID < 68)"
                    [id]="value?.ID"
                    (keydown.space)="utilService.restrictLeadingSpace($event)"
                    autocomplete="off"
                    placeholder="{{ value?.PLACEHOLDER }}"
                    formControlName="ANSWERS"
                    onlynumber
                    max="{{ value?.MAX_VALUE }}"
                    min="{{ value?.MIN_VALUE }}"
                    maxlength="{{ value?.MAX_VALUE?.length }}"
                    [ngClass]="{
                      errorInputBorder:
                        (getArrayControls()
                          [i].get('ANSWERS')
                          ?.hasError('min') ||
                          getArrayControls()
                            [i].get('ANSWERS')
                            ?.hasError('max')) &&
                        getArrayControls()[i].get('ANSWERS')?.touched
                    }"
                  />
                  <div
                    class="d-flex pt-2"
                    id="error_message_{{ value?.ID }}"
                    [ngClass]="
                      (getArrayControls()[i].get('ANSWERS')?.hasError('min') ||
                        getArrayControls()
                          [i].get('ANSWERS')
                          ?.hasError('max')) &&
                      getArrayControls()[i].get('ANSWERS')?.touched
                        ? 'visible'
                        : 'invisible'
                    "
                  >
                    <img
                      src="../../../assets/images/exclamation-circle.svg"
                      alt="Error"
                      class="align-self-start"
                    />
                    <span
                      class="error px-1"
                      *ngIf="
                          getArrayControls()?.[i]?.get('ANSWERS')?.hasError('min') ||
                          getArrayControls()?.[i]?.get('ANSWERS')?.hasError('max')
                          "
                      >Maximale toegestane waarde is
                      {{ value?.MAX_VALUE }}</span
                    >
                  </div>
                </div>
                <ng-template #regularNumberInput>
                  <input
                    class="form-control customInput input-generic"
                    [id]="value?.ID"
                    (keydown.space)="utilService.restrictLeadingSpace($event)"
                    autocomplete="off"
                    placeholder="{{ value?.PLACEHOLDER }}"
                    formControlName="ANSWERS"
                    onlynumber
                    max="{{ value?.MAX_VALUE }}"
                    min="{{ value?.MIN_VALUE }}"
                    maxlength="{{ value?.MAX_VALUE?.length }}"
                    [ngClass]="{
                      errorInputBorder:
                        (getArrayControls()
                          [i].get('ANSWERS')
                          ?.hasError('min') ||
                          getArrayControls()
                            [i].get('ANSWERS')
                            ?.hasError('max')) &&
                        getArrayControls()[i].get('ANSWERS')?.touched
                    }"
                  />
                  <div
                    class="d-flex pt-2"
                    id="error_message_{{ value?.ID }}"
                    [ngClass]="
                      (getArrayControls()[i].get('ANSWERS')?.hasError('min') ||
                        getArrayControls()
                          [i].get('ANSWERS')
                          ?.hasError('max')) &&
                      getArrayControls()[i].get('ANSWERS')?.touched
                        ? 'visible'
                        : 'invisible'
                    "
                  >
                    <img
                      src="../../../assets/images/exclamation-circle.svg"
                      alt="Error"
                      class="align-self-start"
                    />
                    <span
                      class="error px-1"
                      *ngIf="
                          getArrayControls()?.[i]?.get('ANSWERS')?.hasError('min') ||
                          getArrayControls()?.[i]?.get('ANSWERS')?.hasError('max')
                          "
                      >Maximale toegestane waarde is
                      {{ value?.MAX_VALUE }}</span
                    >
                  </div>
                </ng-template>
              </fieldset>
              <!--  Number end -->
              <!-- Multi input start -->
              <ng-container
                *ngSwitchCase="value?.TYPE === 'MULTI_NUMBER'"
                formArrayName="answerlabel"
              >
                <div
                  *ngFor="
                    let labelValue of value?.ANSWER_LABELS;
                    let idx = index
                  "
                  class="d-block d-md-flex"
                >
                  <fieldset
                    [formGroupName]="idx"
                    [disabled]="value?.DISABLED"
                    class="multi-number"
                  >
                    <label class="label w-50"> {{ labelValue }} </label>
                    <input
                      class="w-50 input-generic"
                      placeholder="{{ value?.PLACEHOLDER }}"
                      (keydown.space)="utilService.restrictLeadingSpace($event)"
                      autocomplete="off"
                      formControlName="ANSWER"
                      onlynumber
                      max="{{ value?.MAX_VALUE }}"
                      min="{{ value?.MIN_VALUE }}"
                      maxlength="{{ value?.MAX_VALUE?.length }}"
                      [ngClass]="{
                        errorInputBorder:
                          (getAnswerLabelControls(i)
                            [idx].get('ANSWER')
                            ?.hasError('min') ||
                            getAnswerLabelControls(i)
                              [idx].get('ANSWER')
                              ?.hasError('max')) &&
                          getAnswerLabelControls(i)[idx].get('ANSWER')?.touched
                      }"
                    />
                  </fieldset>
                  <div
                    class="d-flex mb-3 ms-3 align-items-center"
                    id="error_message_{{ value?.ID }}_{{ idx }}"
                    [ngClass]="
                      (getAnswerLabelControls(i)
                        [idx].get('ANSWER')
                        ?.hasError('min') ||
                        getAnswerLabelControls(i)
                          [idx].get('ANSWER')
                          ?.hasError('max')) &&
                      getAnswerLabelControls(i)[idx].get('ANSWER')?.touched
                        ? 'visible'
                        : 'invisible'
                    "
                  >
                    <img
                      src="../../../assets/images/exclamation-circle.svg"
                      alt="Error"
                    />
                    <span
                      class="error px-1"
                      *ngIf="
                        getAnswerLabelControls(i)
                          [idx].get('ANSWER')
                          ?.hasError('min') ||
                        getAnswerLabelControls(i)
                          [idx].get('ANSWER')
                          ?.hasError('max')
                      "
                      >Maximale toegestane waarde is
                      {{ value?.MAX_VALUE }}</span
                    >
                  </div>
                </div>
              </ng-container>
              <!-- Multi input end -->
              <!-- Checkbox start -->
              <ng-container
                *ngSwitchCase="value?.TYPE === 'CHECKBOX'"
                formArrayName="answerlabel"
              >
                <div
                  *ngFor="
                    let labelValue of value?.ANSWER_LABELS;
                    let idx = index
                  "
                  class="d-block d-md-flex"
                >
                  <fieldset [formGroupName]="idx" class="checkbox-input-label">
                    <label class="label w-100">
                      <input
                        type="checkbox"
                        formControlName="ANSWER"
                        checked="value?.ANSWER === 'true'"
                        (change)="
                          value?.RELATED_QUESTIONS?.length &&
                            onAnsweringCheckbox(value, idx)
                        "
                      />
                      {{ labelValue }}
                    </label>
                  </fieldset>
                </div>
              </ng-container>
              <!-- Checkbox end -->
              <!-- Date start -->
              <fieldset
                *ngSwitchCase="value?.TYPE === 'DATE'"
                [disabled]="value?.DISABLED"
              >
                <input
                  class="form-control customInput input-generic"
                  placeholder="{{ value?.PLACEHOLDER }}"
                  name="dp"
                  [id]="value?.ID"
                  (keydown.space)="utilService.restrictLeadingSpace($event)"
                  autocomplete="off"
                  formControlName="ANSWERS"
                  ngbDatepicker
                  #d="ngbDatepicker"
                  (click)="d.toggle()"
                  ngbDatepicker
                  readonly
                  [footerTemplate]="footerTemplate"
                  (keyup)="backSpace($event, i, 'ANSWERS')"
                  [ngClass]="{
                    errorInputBorder:
                      getArrayControls()
                        [i].get('ANSWERS')
                        ?.hasError('ngbDate') &&
                      getArrayControls()[i].get('ANSWERS')?.touched
                  }"
                />
                <ng-template #footerTemplate>
                  <hr class="my-0" />
                  <button
                    class="btn btn-primary btn-sm m-2 float-start"
                    (click)="clearDate(i, 'ANSWERS')"
                  >
                    Clear
                  </button>
                  <button
                    class="btn btn-secondary btn-sm m-2 float-end"
                    (click)="d.close()"
                  >
                    Close
                  </button>
                </ng-template>
              </fieldset>
              <!-- Date end -->
              <!-- Radio button start -->
              <div
                class="d-flex radio align-items-center mt-3"
                *ngSwitchCase="
                  value?.TYPE === 'BOOLEAN' || value?.TYPE === 'BOOLEAN_UNKNOWN'
                "
              >
                <p class="me-5 mb-0">
                  <input
                    type="radio"
                    class="input-generic"
                    id="ja_{{ i }}"
                    [checked]="value?.ANSWERS === 'true'"
                    formControlName="ANSWERS"
                    value="true"
                    (change)="
                      value?.RELATED_QUESTIONS?.length &&
                        onAnsweringRadioButton(value?.RELATED_QUESTIONS, 'Ja')
                    "
                  />
                  <label for="ja_{{ i }}" class="answer">Ja</label>
                </p>

                <p class="me-5 mb-0">
                  <input
                    type="radio"
                    class="input-generic"
                    id="nee_{{ i }}"
                    [checked]="value?.ANSWERS === 'false'"
                    formControlName="ANSWERS"
                    value="false"
                    (change)="
                      value?.RELATED_QUESTIONS?.length &&
                        onAnsweringRadioButton(value?.RELATED_QUESTIONS, 'Nee')
                    "
                  />
                  <label for="nee_{{ i }}" class="answer">Nee</label>
                </p>
                <p class="mb-0" *ngIf="value?.TYPE === 'BOOLEAN_UNKNOWN'">
                  <input
                    type="radio"
                    class="input-generic"
                    id="onbekend_{{ i }}"
                    [checked]="value?.ANSWERS === 'onbekend'"
                    formControlName="ANSWERS"
                    value="unknown"
                  />
                  <label for="onbekend_{{ i }}" class="answer">Onbekend</label>
                </p>
              </div>
              <!-- Radio button end -->
            </div>
            <div
              *ngIf="value?.ID == 55 && !allDependentQuestionsHidden"
              style="padding: 32px 0 0 0"
            >
              <p>
                2. Vul in hoeveel kilo u van de onderstaande afvalstromen heeft genereert in {{refYear}}. Indien niet bekend kunt
                  u een schatting maken. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  </form>
</div>
